import PropsToProvider from 'modules/props-to-provider'
import * as React from 'react'
import { BrowserRouter, RouteComponentProps, withRouter } from 'react-router-dom'

export const RouterContext = React.createContext( {} as RouteComponentProps )

interface CustomBrowserRouterProps {
  children: React.ReactChild
}

const RouterProvider = withRouter( PropsToProvider( RouterContext ) )

export const CustomBrowserRouter: React.FC<CustomBrowserRouterProps> = ( { children } ) => {
  return (
    <BrowserRouter>
      <RouterProvider>
          {children}
      </RouterProvider>
    </BrowserRouter>
  )
}

export default CustomBrowserRouter
