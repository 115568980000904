// import EyeRays from 'components/eye-rays/eye-rays'
import Paper from 'components/paper/paper'
import Pyramid from 'components/pyramid/pyramid'
import { AppContext } from 'context/app-context'
// import useCheckReady from 'hooks/check-ready'
import { useRefDom } from 'hooks/ref-dom'
import { useWindowSize } from 'hooks/window-resize'
import { classes } from 'modules/helpers'
import { Controls } from 'pages/scaffold/Controls'
import * as React from 'react'
import { curry } from 'soultrain/lib/function'
import pipeline from 'soultrain/lib/function/pipeline'
import { ReactComponent as Hemaka } from 'svg/thin-hemaka.svg'
import Location from 'types/location'
import ContactCard from './contact-card/contact-card'
import { getPathTransitions } from './landing-controller'
import { Grid, GridItem } from './landing-grid/landing-grid'
import style from './landing.module.scss'
// const getPaper = () => ( window as any ).paper

const percentageOfWithCutoff = curry( ( cutoff: number, measurement: number, percent: number ): number =>
  measurement > cutoff
  ? cutoff * percent
  : measurement * percent,
)

const PHI = 1.61803398875
// const INVERSE_PHI = 1 / 1.61803398875
interface Rectangle {
  width: number,
  height: number
}
interface MarginedRectangle extends Rectangle {
  marginLeft: number,
  marginRight: number,
}

const twoGoldenRectangles = ( width: number ): [ Rectangle, Rectangle ] => pipeline(
    width / PHI,
    ( bigger ) => [ bigger, width - bigger ],
    ( [ bigger, smaller ] ) => [
      { width: bigger, height: bigger / PHI },
      { width: smaller, height: bigger / PHI },
    ] as [ MarginedRectangle, MarginedRectangle ],
  )

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const twoPositionGoldenRectanglesBiggerLeft = ( width: number, gutter: number, margins: number ): [ MarginedRectangle, MarginedRectangle ] => {
  const availableWidth = width - gutter - ( margins * 2 )
  const [ bigger, smaller ] = twoGoldenRectangles( availableWidth )
  return [
    { marginLeft: margins, marginRight: gutter / 2, ...bigger },
    { marginLeft: gutter / 2, marginRight: margins, ...smaller },
  ]
}

// const verticallyDivide

// const MIN_GUTTER = 15
const MAX_WIDTH = 1000
// const MIN_MARGIN = MIN_GUTTER * 2
// const MIN_MARGIN = MIN_GUTTER * 2

interface LandingProps {
  handleTransition: Controls
  goTo: null | Location
}

let renders = 0

export default ( {handleTransition: { open, close}, goTo }: LandingProps ) => {
  renders = renders + 1
  const timeoutRef = React.useRef( 0 )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ appState, setAppState ] = React.useContext( AppContext )
  // we don't use React's ref here because paper specifically relies on actual dom element to be mounted
  // const [ seaIsReady, canvasDom ] = useCheckReady( () => document.querySelector( '.' + style.water ) )
  const [ waterRef, waterRefDom ] = useRefDom<HTMLCanvasElement>( )
  // const [ foo ] = useCheckReady( () => true, {interval: 1000, attempts: 1} )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { width, height } = useWindowSize()

  const currentWidth = width || window.innerWidth
  const setOffset = percentageOfWithCutoff(
    MAX_WIDTH,
    currentWidth,
  )
  const logoTopOffset = setOffset( -.42 )
  // const seaTopOffset = setOffset( -.25 )
  const seaStatusClass = waterRefDom
    ? style.seaReady
    : style.seaLoading

  const pathTransitions = getPathTransitions( { open, close, appState } )

  // redirect if goTo is set
  if ( goTo !== null && timeoutRef.current === 0 ) {
    timeoutRef.current = window.setTimeout(
      () => {
        pathTransitions[ goTo ]()
        timeoutRef.current = 0
      },
      renders <= 2 ? 1000 : 0,
    )
  }

  return (
    <div className={ style.container }>
      <div className={style.pyramidLogoContainer}>
        <div className={ style.pyramid }>
          <Pyramid />
        </div>
        <div className={ classes( style.logoContainer, seaStatusClass ) } style={{top: logoTopOffset }}>
          <Hemaka />
        </div>
      </div>

      <div className={ style.seaContainer } style={ undefined /*{top: seaTopOffset} */ }>
        <div className={ classes( style.sea, seaStatusClass ) }>
          <canvas className={ style.water } ref={ waterRef } style={{ minWidth: 1000, width }}></canvas>
        </div>
        <div className={ classes( style.submerged, seaStatusClass ) }>
          <Grid layout='endpain'>
            <GridItem name='endpain' onClick={ pathTransitions[ '/endpain' ] } />
            <GridItem name='cosmic' onClick={ pathTransitions[ '/cosmic' ] } />
            <GridItem name='gjelina' href='https://www.gjelina.com' target='_blank' />
            <GridItem name='articles' href='https://www.gjelinatakeaway.com' target='_blank' />
            <GridItem name='contact'>
              <ContactCard />
            </GridItem>
          </Grid>

          {/* <ContactInfo /> */}
        </div>
        <div className={ classes( style.bottomFiller, seaStatusClass ) } >
          <div className={ style.quoteContainer } >
            <div className={ style.quote }>
              <div className={ style.quote__text }>Imagination will often carry us to worlds that never were. <br className={ style.quote__break } />But without it we go nowhere.</div>
              <div className={ style.quote__author}>Carl Sagan</div>
            </div>
          </div>
        </div>

        {/* <div className={ style.offsetCorrection }>
            <EyeRays />
        </div> */}
      </div>

      { ( waterRefDom !== null ) ? <Paper canvas={ waterRefDom } /> : '' }
    </div>
  )
}
