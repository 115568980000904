import * as React from 'react'

interface CloseProps extends React.CSSProperties {
  color: string
  className?: string
  onClick?: ( event: React.MouseEvent<HTMLElement> ) => void
  style?: React.CSSProperties
}

const Close: React.FC<CloseProps> = ( { color, onClick, className, style, ...props } ) => (
  <div onClick={ onClick } className={ className } style={{ ...style, stroke: 'white' }} { ...props } >
    <svg width='100%' height='100%' viewBox='0 0 564 564' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' xmlSpace='preserve' style={ style }>
      <g transform='matrix(5.55556,0,0,5.55556,-6381.98,-607.81)'>
          <path d='M1164.51,123.144L1164.89,123.42L1236.16,194.691L1236.44,195.071L1236.58,195.517L1236.58,195.986L1236.44,196.433L1236.16,196.812L1235.78,197.088L1235.34,197.233L1234.87,197.233L1234.42,197.088L1234.04,196.812L1162.77,125.541L1162.5,125.162L1162.35,124.715L1162.35,124.246L1162.5,123.8L1162.77,123.42L1163.15,123.144L1163.6,122.999L1164.07,122.999L1164.51,123.144Z' style={{ fill: color }} />
      </g>
      <g transform='matrix(5.55556,0,0,5.55556,-6381.98,-607.81)'>
          <path d='M1235.78,123.144L1236.16,123.42L1236.44,123.8L1236.59,124.246L1236.59,124.715L1236.44,125.162L1236.16,125.541L1164.89,196.812L1164.51,197.088L1164.07,197.233L1163.6,197.233L1163.15,197.088L1162.77,196.812L1162.5,196.433L1162.35,195.986L1162.35,195.517L1162.5,195.071L1162.77,194.691L1234.04,123.42L1234.42,123.144L1234.87,122.999L1235.34,122.999L1235.78,123.144Z' style={{ fill: color }} />
      </g>
    </svg>
  </div>
)

export default Close
