// import { Elastic, Power1, TweenMax } from 'gsap'
import { Position } from '../Position'
const firefoxBugCorrectionContainer = document.createElement( 'div' )

export class Leaf {
  leaf: SVGPathElement

  constructor( stage: HTMLElement, position: Position, size: number ) {

    this.leaf = document.createElementNS( 'http://www.w3.org/2000/svg', 'path' )
    this.leaf.setAttribute( 'd', this.createLeafPath( {x: 0, y: 0}, size ) )
    this.leaf.setAttribute( 'class', 'leaf' )
    this.leaf.style.fill = this.getColor()
    this.leaf.style.stroke = 'none'

    const rotate = Math.random() * 360
    const rotateGrow = ( Math.random() * 180 ) - 90

    const TweenMax = ( window as any ).TweenMax
    const Elastic = ( window as any ).Elastic

    // order is important for FireFox
    firefoxBugCorrectionContainer.appendChild( this.leaf )

    TweenMax.set( this.leaf, {scale: 0, x: position.x, y: position.y, rotation: rotate} )
    TweenMax.to( this.leaf, 4, {scale: 1} )
    TweenMax.to( this.leaf, 6, {rotation: rotate + rotateGrow, ease: Elastic.easeOut} )
    stage.appendChild( this.leaf )

  }

  public clear() {
    this.leaf.remove()
  }

  private createLeafPath( p: Position, size: number ): string {
    const top = size * ( 3 + Math.random() * 2 )
    const middle = size * ( 1 + Math.random() )
    const width = size * ( 1.5 + Math.random() * 0.5 )
    const path = `M ${p.x} ${p.y} Q ${p.x - width} ${p.y + middle}  ${p.x} ${p.y + top} Q ${p.x + width} ${p.y + middle} ${p.x} ${p.y} Z`
    return path
  }

  private getColor(): string {
    const greens = [ '#00A676', '#00976C', '#008861', '#007956' ]
    return greens[ Math.floor( Math.random() * greens.length ) ]
  }
}
