import json2mq from 'json2mq'
import { useEffect, useState } from '../modules/react-16.7-features'

type MediaKeys =
  'AnyHover' |
  'AnyPointer' |
  'AspectRatio' |
  'Color' |
  'ColorGamut' |
  'ColorIndex' |
  'Grid' |
  'Height' |
  'Hover' |
  'InvertedColors' |
  'LightLevel' |
  'MaxAspectRatio' |
  'MaxColor' |
  'MaxColorIndex' |
  'MaxHeight' |
  'MaxMonochrome' |
  'MaxResolution' |
  'MaxWidth' |
  'MinAspectRatio' |
  'MinColor' |
  'MinColorIndex' |
  'MinHeight' |
  'MinMonochrome' |
  'MinResolution' |
  'MinWidth' |
  'Monochrome' |
  'Orientation' |
  'OverflowBlock' |
  'OverflowInline' |
  'Pointer' |
  'Resolution' |
  'Scan' |
  'Scripting' |
  'Update' |
  'Width'

interface MediaQuery {
  anyHover?: 'none' | 'hover'
  anyPointer?: 'none' | 'coarse' | 'fine'
  aspectRatio?: string
  color?: boolean
  colorGamut?: 'srgb' | 'p3' | 'rec2020'
  colorIndex?: boolean

  // deprecated
  deviceAspectRatio?: string
  // deprecated
  deviceHeight?: number | string
  // deprecated
  deviceWidth?: number | string
  // deprecated
  maxDeviceWidth?: number | string
  // deprecated
  maxDeviceHeight?: number | string

  grid?: 0 | 1
  height?: number | string
  hover?: 'none' | 'hover'
  invertedColors?: 'none' | 'inverted'
  lightLevel?: 'dim' | 'normal' | 'washed'
  maxAspectRatio?: number
  maxColor?: number
  maxColorIndex?: number
  maxHeight?: number | string
  maxMonochrome?: number | string
  maxResolution?: number | string
  maxWidth?: number | string
  minAspectRatio?: string
  minColor?: number
  minColorIndex?: number
  minHeight?: number | string
  minMonochrome?: number
  minResolution?: string
  minWidth?: number | string
  monochrome?: boolean
  orientation?: 'portrait' | 'landscape'
  overflowBlock?: 'none' | 'scroll' | 'optional-paged' | 'paged'
  overflowInline?: 'none' | 'scroll'
  pointer?: 'none' | 'coarse' | 'fine'
  resolution?: number | string
  scan?: 'interlace' | 'progressive'
  scripting?: 'none' | 'initial-only' | 'enabled'
  update?: 'none' | 'slow' | 'fast'
  width?: number | string
}

interface Json2mq extends MediaQuery {
  screen?: boolean
  handheld?: boolean
}

export function useMedia( query: string | Json2mq | Json2mq[], defaultMatches: boolean | null = null ): boolean | null {
  const [ matches, setMatches ] = useState( defaultMatches )

  useEffect( () => {
    const mediaQueryList = window.matchMedia(
      typeof query === 'string' ? query : json2mq( query ),
    )
    let active = true

    const listener = () => {
      if ( !active ) {
        return
      }

      if ( mediaQueryList.matches ) {
        setMatches( true )
      } else {
        setMatches( false )
      }
    }

    mediaQueryList.addListener( listener )
    setMatches( mediaQueryList.matches )

    return () => {
      active = false
      mediaQueryList.removeListener( listener )
    }
  }, [ query ] )

  return matches
}
