import * as React from 'react'

export const useTimeout = ( fn: () => void, time: number ) => {
  const sideEffect = () => {
    const setTimeoutId = setTimeout( fn, time )
    return () => clearTimeout( setTimeoutId )
  }
  React.useEffect( sideEffect, [ ] )
}

export default useTimeout
