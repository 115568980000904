import { State } from 'context/app-context'
import transitions from 'models/navigation'
import { TransitionOptions, TransitionPage } from 'modules/shape-overlay'
import { Controls } from 'pages/scaffold/Controls'
import * as React from 'react'

interface GetPathTransitionsProps extends Controls {
  appState: State
}

export const getPathTransitions = ( { open, close, appState}: GetPathTransitionsProps ) => {
  /**
   * Configure open and close animations
   * @param Component the component to render after the transition
   * @param transitionOptions object of type TransitionOptions, describes transition parameters and open direction
   * @param direction close direction
   */
  const shortCut = ( transitionOptions: TransitionOptions ) => {
    return open(
      transitionOptions,
      () => React.createElement<TransitionPage>(
        transitionOptions.component, { close: close( { ...transitionOptions, direction: transitionOptions.closeDirection } ) },
      ),
    )
  }
  // handle direct links
  return {
    '/' : () => {
      if ( appState.location !== '/' ) {
        const config = transitions[ appState.location  ]
        close( { ...config, direction: config.closeDirection } )( {} as React.MouseEvent<HTMLElement> )
      }
    },
    '/cosmic' : () => shortCut( transitions[ '/cosmic' ] )( {} as React.MouseEvent<HTMLElement> ),
    '/endpain' : () => shortCut( transitions[ '/endpain' ] )( {} as React.MouseEvent<HTMLElement> ),
  }
}
