import './app.scss'
import { Branch, Out } from './branch/branch'
import { BranchSettings } from './branch/Settings'
import { BranchState } from './branch/State'
// import { Pkg } from './package'
import { Position } from './Position'
import { Thorn } from './thorn/thorn'

import { Observable } from 'rxjs/Observable'

// import { TweenMax } from 'gsap'
import { Flower } from './flower/flower'
import { FlowerColors } from './flower/FlowerColors'
import { Leaf } from './leaf/leaf'

// const html = require('./app.html');

export class App {
  private container: HTMLElement
  // private downloadButton: HTMLElement
  private svg: HTMLElement
  private branches: Branch[] = []
  private thorns: Thorn[] = []
  private flowers: Flower[] = []
  private leaves: Leaf[] = []

  private width: number = 600
  private height: number = 600
  private animations: number = 0
  // private lastMousePosition: Position
  // private direction: Position

  private branchGroup: HTMLElement
  private thornGroup: HTMLElement
  private leafGroup: HTMLElement
  private flowerGroup: HTMLElement

  private grid: number = 40
  private branchCount = 16
  private flowerColors?: FlowerColors

  // private path: string = 'M 533.5 407 Q 534.8496409486341 407 , 534.8496409486341 402.36977393948155 , 534.8496409486341 393.2018833298724 , 544.3053380202142 393.2018833298724 , 552.0853781543462 393.2018833298724 , 552.0853781543462 405.03504479468944 , 552.0853781543462 425.58022672568524 , 564.2489906471741 425.58022672568524 , 584.9819756521467 425.58022672568524 , 584.9819756521467 397.17999658666747 , 584.9819756521467 372.78395456942127 , 601.7370597926894 372.78395456942127 , 628.3659554958527 372.78395456942127 , 628.3659554958527 336.86218123420764 , 628.3659554958527 295.58188962461617 , 659.4618452804948 295.58188962461617 , 686.4922135741175 295.58188962461617 , 686.4922135741175 352.1262866288541 , 686.4922135741175 394.78736737228553 , 658.5157273508256 394.78736737228553 , 631.9464076743959 394.78736737228553 , 631.9464076743959 461.1496330757915 , 631.9464076743959 519.4386294898501 , 685.0775707054867 519.4386294898501 , 716.4222228019987 519.4386294898501 , 716.4222228019987 577.3675411618034 , 716.4222228019987 667.9582252858412 , 663.6920457387405 667.9582252858412 , 622.697390273965 667.9582252858412 , 622.697390273965 603.4256558079187 , 622.697390273965 533.4016904019671 , 545.9424065153385 533.4016904019671 , 501.2340245026321 533.4016904019671 , 501.2340245026321 470.86240318299997 , 501.2340245026321 406.40106952788943 , 552.6723617691031 406.40106952788943 , 621.6138602860023 406.40106952788943 , 621.6138602860023 271.7405722471425 , 621.6138602860023 181.13219201307305 , 690.9342801777517 181.13219201307305 , 749.5047011968077 181.13219201307305 , 749.5047011968077 40.161473379475865 , 749.5047011968077 -68.16573444231605 , 688.5254790047642 -68.16573444231605 , 580.7531458903497 -68.16573444231605 , 580.7531458903497 17.404044210728657 , 580.7531458903497 178.16841930072957 , 667.8949778387896 178.16841930072957'

  // codpen path
  // private path:string = 'M64.6-37.7H16.2v75.4h48.5 M247.7-37.7h-48.5v75.4h48.5 M231.5,0h-32.3 M48.5,0H16.2 M290.8,37.7v-75.4 L350,37.7v-75.4 M-247.7,19.9l59.2,39.3l59.2-39.3v-39.8l-59.2-39.3l-59.2,39.3V19.9z M-129.2,19.9l-59.2-39.8l-59.2,39.8 M-247.7-19.9l59.2,39.3l59.2-39.3 M-188.5-59.2v39.3 M-188.5,19.9v39.3 M107.7,5.4H140c11.8,0,21.5-9.7,21.5-21.5 s-9.7-21.5-21.5-21.5h-32.3v75.4 M-285.4-26.9c-7-7-16.2-10.8-26.9-10.8C-333.8-37.7-350-21.5-350,0s16.2,37.7,37.7,37.7 c10.8,0,19.9-4.3,26.9-10.8 M-21.5,0c0,21.5-16.2,37.7-37.7,37.7h-26.9v-75.4h26.9C-37.7-37.7-21.5-21.5-21.5,0z';

  // box path
  // private path:string = 'M -250 -250, L 250 -250, 250 250, -250 250 Z';

  // random path
  private path?: string
  private isMobile: boolean
  constructor( container: HTMLElement, mobile: boolean, {
    app,
    stage,
    branch,
    thorn,
    leaf,
    flower,
  }: Record<string, HTMLElement> ) {
    // console.log(Pkg().version);
    // ( window as any ).foo = container
    this.isMobile = mobile
    this.container = container
    // const arbitraryId = `arbitrary-${ Math.ceil( Math.random() * 1000 ) }`
    // this.container.innerHTML = `
    // <svg id="${ arbitraryId }-stage" style="height: 100%; width: 100%;" xmlns="http://www.w3.org/2000/svg">
    //     <g >
    //       <g id="${ arbitraryId }-branchGroup"></g>
    //       <g id="${ arbitraryId }-thornGroup"></g>
    //       <g id="${ arbitraryId }-leafGroup"></g>
    //       <g id="${ arbitraryId }-flowerGroup"></g>
    //     </g>
    // </svg>`
    this.svg = container // this.container.querySelector( `#${ arbitraryId }-stage` ) as HTMLElement

    // this.branchGroup = this.container.querySelector( `#${ arbitraryId }-branchGroup` ) as HTMLElement
    // this.thornGroup = this.container.querySelector( `#${ arbitraryId }-thornGroup` ) as HTMLElement
    // this.leafGroup = this.container.querySelector( `#${ arbitraryId }-leafGroup` ) as HTMLElement
    // this.flowerGroup = this.container.querySelector( `#${ arbitraryId }-flowerGroup` ) as HTMLElement

    this.branchGroup = branch
    this.thornGroup = thorn
    this.leafGroup = leaf
    this.flowerGroup = flower

    this.onResize()

    this.tick()

    Observable.fromEvent<MouseEvent>( this.container, 'click' )
      .throttleTime( 500 )
      .map( ( mouseEvent: MouseEvent ) => {
        mouseEvent.preventDefault()
        const boundaries = this.container.getBoundingClientRect()

        return {
          x: mouseEvent.clientX - boundaries.left,
          y: mouseEvent.clientY - boundaries.top,
        }
      } )
      .subscribe( ( position: Position ) => {
        if ( this.isMobile && this.animations > 15 ) {
          this.clearOld()
        } else if ( this.animations > 60 ) {
          this.clearOld()
        }
        this.startBranch( this.branchCount, position, true ) // , this.path )
      } )

    // this.container.addEventListener( 'click', ( event: MouseEvent ) => {
    //   event.preventDefault()
    //   const boundaries = this.container.getBoundingClientRect()
    //   const position = {
    //     x: event.clientX - boundaries.left,
    //     y: event.clientY - boundaries.top,
    //   }
    //   // this.clearOld()
    //   this.startBranch( this.branchCount, position, true )
    // } )

    // window.addEventListener( 'resize', this.onResize )

    // this.startBranch(this.branchCount, {x: this.width / 2, y: this.height / 2}, true, this.path, true);
    if ( this.isMobile ) {
      this.startBranch( this.branchCount, {x: this.width * 4 / 5, y: this.height * 1 / 4}, true, this.path, false )
      this.startBranch( this.branchCount, {x: this.width * 4 / 5, y: this.height * 4 / 5}, true, this.path, false )
    } else {
      this.startBranch( this.branchCount, {x: this.width * 1 / 5, y: this.height * 1 / 8}, true, this.path, false )
      this.startBranch( this.branchCount, {x: this.width * 4 / 5, y: this.height * 4 / 5}, true, this.path, false )
      setTimeout( () => this.startBranch( this.branchCount, {x: ( this.width * 4 / 5 ), y: this.height * 1 / 6 }, true, this.path, false ), 100 )
    }

    return this
    // Observable.fromEvent( window, 'resize' ).subscribe( () => this.onResize() )
  }

  clearOld() {
    this.branches.map( ( branch: Branch ) => {
      branch.clear()
      return void 0
    } )
    this.thorns.map( ( thorn: Thorn ) => thorn.clear() )
    this.flowers.map( ( flower: Flower ) => flower.clear() )
    this.leaves.map( ( leaf: Leaf ) => leaf.clear() )

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ignore = ( window as any ).TweenMax.killAll()

    this.branches = []
    this.thorns = []
    this.flowers = []
    this.leaves = []
    this.animations = 0
  }

  private startBranch( sections: number, position: Position, setColors: boolean = false, setPath: string | null = null, print = false ) {
    this.animations = this.animations + 1
    if ( setColors ) {
      this.flowerColors = {
        inner: this.getColor(),
        outer: this.getColor(),
      }
    }
    // console.log("​sections", sections)

    let dx = Math.random()
    if ( dx > 0.5 ) { dx = dx > 0.75 ? 1 : -1 } else { dx = 0 }
    const dy = 0
    if ( dx === 0 ) { dx = Math.random() > 0.5 ? 1 : -1 }

    const settings: BranchSettings = {
      directionX: dx,
      directionY: dy,
      length: 0,
      maxSections: this.branchCount,
      progress: 0,
      sections,
      stageHeight: this.container.offsetHeight,
      stageWidth: this.container.offsetWidth,
      topColors: sections === this.branchCount ? [ 'chocolate' ] : [ '#6FCAB1' ],
      underColors: sections === this.branchCount ? [ 'saddlebrown' ] : [ '#646F4B' ],
      x: position.x,
      y: position.y,
    }
    const newBranch = new Branch( this.branchGroup, settings, this.grid / 2 + Math.random() * this.grid / 2, this.branches.length > 1 ? this.branches[ this.branches.length - 2 ] : null, setPath, print )

    if ( sections === 16 ) {

      newBranch.branchOut.debounceTime( 50 ).subscribe( ( out: Out ) => this.startBranch( out.sections as number, out.position ) )
      // newBranch.branchOut.debounceTime(50).subscribe((out:Out) => this.startBranch(out.sections, out.position, false, bro))
      newBranch.thornOut.debounceTime( 50 ).subscribe( ( out: Out ) => this.thorns.push( new Thorn( this.thornGroup, out.position, out.width ? out.width : 0 ) ) )
    } else {
      // newBranch.branchOut.debounceTime(200).subscribe((out:Out) => this.startBranch(out.sections, out.position))
      // newBranch.thornOut.debounceTime(100).subscribe((out:Out) => this.thorns.push(new Thorn(this.thornGroup, out.position, out.width)))
      if ( this.flowerColors !== undefined ) {
        newBranch.flowerOut.debounceTime( 300 ).subscribe( ( out: Out ) => this.flowers.push( new Flower( this.flowerGroup, out.position, out.width ? out.width : 0, this.flowerColors as FlowerColors ) ) )
      }
      newBranch.leafOut.debounceTime( 50 ).subscribe( ( out: Out ) => this.leaves.push( new Leaf( this.leafGroup, out.position, out.width ? out.width : 0 ) ) )
    }
    this.branches.push( newBranch )
  }

  private onResize() {

    // Needed?

    if ( this.container ) {
      this.width = this.container.offsetWidth
      this.height = this.container.offsetHeight
      // this.svg.setAttribute( 'width', String( this.width ) )
      // this.svg.setAttribute('height', '10');

      const TweenMax = ( window as any ).TweenMax

      if ( this.path ) {
        TweenMax.set( this.branchGroup, {x: this.width / 2, y: this.height / 2} )
        TweenMax.set( this.thornGroup, {x: this.width / 2, y: this.height / 2} )
        TweenMax.set( this.leafGroup, {x: this.width / 2, y: this.height / 2} )
        TweenMax.set( this.flowerGroup, {x: this.width / 2, y: this.height / 2} )
      }
    }

  }

  private tick() {
    let step = this.branches.length - 1

    while ( step >= 0 ) {
      if ( this.branches[ step ].state !== BranchState.ended ) {
        this.branches[ step ].update()

      } else {
        // this.branches[step] = null;
        // this.branches.splice(step, 1);
      }

      --step
    }

    requestAnimationFrame( () => this.tick() )
  }

  private getColor(): string {
    const offset = Math.round( Math.random() * 100 )
    const r = Math.sin( 0.3 * offset ) * 100 + 155
    const g = Math.sin( 0.3 * offset + 2 ) * 100 + 155
    const b = Math.sin( 0.3 * offset + 4 ) * 100 + 155
    return '#' + this.componentToHex( r ) + this.componentToHex( g ) + this.componentToHex( b )
  }

  private componentToHex( c: number ) {
    const hex = Math.round( c ).toString( 16 )
    return hex.length === 1 ? '0' + hex : hex
  }
}
