// import { Power1, TweenMax } from 'gsap'
import { Subject } from 'rxjs'
import { Position } from '../Position'
import { BranchSettings } from './Settings'
import { BranchState } from './State'

interface BranchSet {
  path: SVGPathElement
  settings: BranchSettings
}

export interface Out {
  position: Position
  width?: number
  sections?: number
}

export class Branch {
  public branches: BranchSet[] = []
  public state: BranchState = BranchState.ready

  public branchOut: Subject<Out> = new Subject()
  public thornOut: Subject<Out> = new Subject()
  public flowerOut: Subject<Out> = new Subject()
  public leafOut: Subject<Out> = new Subject()
  public print: boolean
  private grid: number
  private stage: HTMLElement
  private branch?: SVGPathElement
  private settings?: BranchSettings
  private placeBehind: Branch

  constructor( stage: HTMLElement, settings: BranchSettings, grid: number = 40, placeBehind: Branch | null = null, setPath: string | null = null, print = false ) {
    this.grid = grid
    this.stage = stage
    this.placeBehind = placeBehind as Branch
    this.print = print
    settings.width = 2
    settings.opacity = 1

    this.state = BranchState.animating
    const path = setPath ? setPath : this.createLine( settings )
    if ( print ) {
      // tslint:disable-next-line:no-console
      console.log( 'path', path )
    }
        // console.log(path)
    const branchCount: number = 2
        // console.log('settings',settings)
    for ( let i = 0; i < branchCount; i++ ) {
      this.createSqwig( i, branchCount, path, settings )
    }
  }

  createSqwig( index: number, total: number, path: string, _settings: BranchSettings ) {
    const settings = { ..._settings } as BranchSettings
    const branch = document.createElementNS( 'http://www.w3.org/2000/svg', 'path' )
    branch.setAttribute( 'd', path )
    branch.style.fill = 'none'
    branch.style.stroke = this.getColor( index, settings )
    branch.style.strokeLinecap = 'round'
    settings.length = branch.getTotalLength() as number
    settings.progress = settings.length

    branch.style.strokeDasharray = `${settings.length}, ${settings.length}`
    branch.style.strokeDashoffset = `${settings.length}`

    this.branches.push( {path: branch, settings} )
    if ( !this.placeBehind ) { this.stage.appendChild( branch ) } else { this.stage.insertBefore( branch, this.placeBehind.branches[ 0 ].path ) }

    // thin it here if mobile
    // prevent multi click on mobile
    const widthTarget = settings.sections * (
            settings.sections === settings.maxSections ? 0.9 : 0.5
        )

    const TweenMax = ( window as any ).TweenMax
    const Power1  = ( window as any ).Power1
    TweenMax.set( branch, {x: -index * 2, y: -index * 2} )

    TweenMax.to( settings, settings.sections * (
            settings.sections === settings.maxSections
                ? 1
                : 0.4
        ), {
          delay: index * ( settings.sections * 0.001 ),
          ease: Power1.easeOut,
          onComplete: () => {
            index = total - 1
            if ( index ) { this.state = BranchState.ended }
                // branch.remove();
          },
          onUpdate: () => {
            if ( index === 0 && settings.sections > 4 ) {
              const choice = Math.random()
              const length = settings.length - settings.progress
              const pos = branch.getPointAtLength( length )

              let sec = Math.ceil( ( settings.progress / settings.length ) * settings.sections ) - 2
              if ( sec < 4 ) { sec = 4 }

              const out: Out = {
                position: {x: pos.x, y: pos.y},
                sections: sec,
                width: widthTarget,
              }

              if ( choice < 0.02 ) { this.branchOut.next( out ) } else if ( choice < 0.1 ) { this.thornOut.next( out ) } else if ( choice < 0.3 ) { this.flowerOut.next( out ) } else if ( choice < 0.5 ) { this.leafOut.next( out ) }
            }
          },
          progress: 0,
          width: widthTarget,
        } )
  }

  public update() {
    this.branches.map( ( set: BranchSet ): void => {
      set.path.style.strokeDashoffset = `${set.settings.progress}`
      set.path.style.strokeWidth = `${set.settings.width}px`
      return void 0
            // set.path.style.opacity = `${set.settings.opacity}`;
    } )

  }

  public clear() {
        // this.branchOut.complete();
        // this.thornOut.complete();
        // this.leafOut.complete();
        // this.flowerOut.complete();
    this.branches.map( ( set: BranchSet ) => set.path.remove() )
  }

  private createLine( settings: BranchSettings ): string {
    let x = settings.x
    let y = settings.y
    let dx = settings.directionX
    let dy = settings.directionY
    const path: string[] = [
      'M',
      '' + x,
      '' + y,

    ]

    const steps = settings.sections * (
            settings.sections === settings.maxSections
                ? 1.5
                : 1
        )
    let step = 0
    const getNewDirection = ( direction: string, goAnywhere: boolean ) => {
      const randomDirection =  Math.random() < 0.5 ? -1 : 1
      return (
                direction === 'x'
                    ? settings.x < 0 || settings.x > settings.stageWidth // window.innerWidth
                        ? settings.x < 0
                            ? 1
                            : -1
                        : randomDirection
                    : settings.y < 0 || settings.y > settings.stageHeight
                        ? settings.y < 0
                            ? 1
                            : -1
                        : randomDirection
      )
            // if(!goAnywhere && settings['direction' + direction.toUpperCase()] != 0) return settings['direction' + direction.toUpperCase()];

    }
        // console.log('asdf',settings.sections, settings.x, this.stage.clientWidth)

        // console.log({steps})

    if ( steps * 2 > step ) { path.push( 'Q' ) }

    while ( step < steps * 2 ) {
      step++
      const stepUp = this.stepUp( step )
      x += ( dx * stepUp ) * (
                settings.sections === settings.maxSections
                    ? this.grid * .7
                    : this.grid * 1.5
            )
      y += ( dy * stepUp ) * this.grid
      if ( step !== 1 ) { path.push( ',' ) }
      path.push( '' + x )
      path.push( '' + y )

      if ( step % 2 !== 0 ) {
        dx = dx === 0 ? getNewDirection( 'x', step > 8 ) : 0
        dy = dy === 0 ? getNewDirection( 'y', step > 8 ) : 0
      }
    }
        // console.log({path})
    return path.join( ' ' )
  }

  private stepUp( step: number ): number {
    const r = Math.random() * 10
    return step / ( 10 + r )
  }

  private getColor( index: number, settings: BranchSettings ): string {

    const base = settings.underColors // ['#646F4B']
    const greens = settings.topColors // ['#6FCAB1'];//, '#5DC4A8', '#4BBD9E', '#3AB795', '#A7CCBA', '#91C0A9', '#86BAA1']

        // return index == 0 ? '#646F4B' : '#77B28C';
    const chooseFrom = index === 0 ? base : greens
        // let offset = Math.round(Math.random() * 100)
        // var r = Math.sin(0.3 * offset) * 10 + 100;
        // var g = Math.sin(0.3 * offset + 2) * 100 + 155;
        // var b = Math.sin(0.3 * offset + 4) * 10 + 100;
        // return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
    return chooseFrom[ Math.floor( Math.random() * chooseFrom.length ) ]
  }

    // private componentToHex(c:number)
    // {
    //     var hex = Math.round(c).toString(16);
    //     return hex.length == 1 ? "0" + hex : hex;
    // }
}
