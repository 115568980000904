// import { Elastic, Power1, TweenMax } from 'gsap'
import { Position } from '../Position'
import { FlowerColors } from './FlowerColors'
const firefoxBugCorrectionContainer = document.createElement( 'div' )

export class Flower {
  petals: SVGPathElement[] = []

  constructor( stage: HTMLElement, position: Position, size: number, colors: FlowerColors ) {
        // outer petals

    let petalCount = 8
    let p = petalCount
    let rotateAmount = 360 / petalCount
    const growRotation = ( Math.random() * 100 ) - 50

    const TweenMax = ( window as any ).TweenMax
    const Elastic = ( window as any ).Elastic

    while ( p > 0 ) {
      --p
      const petal = document.createElementNS( 'http://www.w3.org/2000/svg', 'path' )
      petal.setAttribute( 'd', this.createPetalPath( {x: 0, y: 0}, size ) )
      petal.setAttribute( 'class', 'petal' )
      petal.style.fill = colors.outer
      petal.style.stroke = 'none'

      this.petals.push( petal )
      const rotate = ( rotateAmount * p ) + Math.random() * 30

      // order is important for FireFox
      firefoxBugCorrectionContainer.appendChild( petal )

      TweenMax.set( petal, {scale: 0, x: position.x, y: position.y, rotation: rotate} )
      const delay = Math.random()
      TweenMax.to( petal, 3, {scale: 1, delay} )
      TweenMax.to( petal, 6, {rotation: '+=' + growRotation, delay, ease: Elastic.easeOut} )
      stage.appendChild( petal )

    }

        // inner petals

    petalCount = 6
    p = petalCount
    rotateAmount = 360 / petalCount
    while ( p > 0 ) {
      --p
      const petal = document.createElementNS( 'http://www.w3.org/2000/svg', 'path' )
      petal.setAttribute( 'd', this.createPetalPath( {x: 0, y: 0}, size / 2 ) )
      petal.setAttribute( 'class', 'petal' )
      petal.style.fill = colors.inner
      petal.style.stroke = 'none'

      this.petals.push( petal )
      const rotate = ( rotateAmount * p ) + Math.random() * 30

      firefoxBugCorrectionContainer.appendChild( petal )

      TweenMax.set( petal, {scale: 0, x: position.x, y: position.y, rotation: rotate} )
      TweenMax.to( petal, 12, {scale: 1, rotation: '+=' + growRotation, delay: 1 + Math.random(), ease: Elastic.easeOut} )
      stage.appendChild( petal )

    }
  }

  public clear() {
    this.petals.map( ( petal: SVGPathElement ) => petal.remove() )
  }

  private createPetalPath( p: Position, size: number ): string {
    const top = size * 4
    const middle = size * 1.8
    const width = size
    const path = `M ${p.x} ${p.y} Q ${p.x - width} ${p.y + middle}  ${p.x} ${p.y + top} Q ${p.x + width} ${p.y + middle} ${p.x} ${p.y} Z`
    return path
  }
}
