export default {
  circularInOut: ( t: number ) => {
    return t < 0.5
        ? 0.5 * ( 1.0 - Math.sqrt( 1.0 - 4.0 * t * t ) )
        : 0.5 * ( Math.sqrt( ( 3.0 - 2.0 * t ) * ( 2.0 * t - 1.0 ) ) + 1.0 )
  },
  cubicIn: ( t: number ) => {
    return t * t * t
  },
  cubicInOut: ( t: number ) => {
    return t < 0.5
      ? 4.0 * t * t * t
      : 0.5 * Math.pow( 2.0 * t - 2.0, 3.0 ) + 1.0
  },
  cubicOut: ( t: number ) => {
    const f = t - 1.0
    return f * f * f + 1.0
  },
  exponentialIn: ( t: number ) => {
    return t === 0.0 ? t : Math.pow( 2.0, 10.0 * ( t - 1.0 ) )
  },
  exponentialInOut: ( t: number ) => {
    return t === 0.0 || t === 1.0
    ? t
    : t < 0.5
    ? +0.5 * Math.pow( 2.0, ( 20.0 * t ) - 10.0 )
    : -0.5 * Math.pow( 2.0, 10.0 - ( t * 20.0 ) ) + 1.0
  },
  exponentialOut: ( t: number ) => {
    return t === 1.0 ? t : 1.0 - Math.pow( 2.0, -10.0 * t )
  },
  quadraticOut: ( t: number ) => {
    return -t * ( t - 2.0 )
  },
  quarticOut: ( t: number ) => {
    return Math.pow( t - 1.0, 3.0 ) * ( 1.0 - t ) + 1.0
  },
  sineOut: ( t: number ) => {
    const HALF_PI = 1.5707963267948966
    return Math.sin( t * HALF_PI )
  },
}
