import { classes } from 'modules/helpers'
import * as React from 'react'
import style from './landing-grid.module.scss'

interface GridItemProp {
  name: keyof typeof style,
  onClick?: () => void
  href?: string,
  target?: string,
  children?: React.ReactChild,
}

export const GridItem: React.FC<GridItemProp> = ( {name, onClick, href, target, children } ) => {
  return children
    ? (
      <div
        className={ classes( style.gridItem, style[ name ] ) }
        onClick={ onClick }>
        { children }
      </div>
    )
    : (
      <a
        className={ classes( style.gridItem, style[ name ] ) }
        onClick={ onClick }
        href={ href }
        rel='noopener noreferrer'
        target={ target }>
      </a>
  )
}

interface GridProps {
  layout: 'cosmic' | 'endpain'
  children: React.ReactChild[]
}

export const Grid: React.FC<GridProps> = ( { layout, children } ) => {
  const gridLayout = layout + 'Layout' as ( 'cosmicLayout' | 'endpainLayout' )
  return (
    <div className={ classes( style.gridContainer, style[ gridLayout ] ) }>
      { children }
    </div>
  )
}
