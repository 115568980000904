import React from 'react'

export function throttle<T extends ( ...args: any[] ) => void>(
  func: T,
  threshhold: number = 250,
  scope?: any,
): T {
  let last: number
  let deferTimer: number
  return function( this: any ) {
    const context = scope || this

    const now = Date.now()
    const args = Array.prototype.slice.call( arguments )
    if ( last && now < last + threshhold ) {
      // hold on to it
      clearTimeout( deferTimer )
      deferTimer = window.setTimeout( () => {
        last = now
        func.apply( context, args )
      }, threshhold )
    } else {
      last = now
      func.apply( context, args )
    }
  } as T
}

const events = new Set<() => void>()
const onResize = () => events.forEach( ( fn ) => fn() )

export const useWindowSize = ( options: { throttleMs?: number } = {} ) => {
  const { throttleMs = 100 } = options

  const [ size, setSize ] = React.useState( {
    height: window.innerHeight,
    width: window.innerWidth,
  } )

  const handle = throttle( () => {
    setSize( {
      height: window.innerHeight,
      width: window.innerWidth,
    } )
  }, throttleMs )

  React.useEffect( () => {
    if ( events.size === 0 ) {
      window.addEventListener( 'resize', onResize, true )
    }

    events.add( handle )

    return () => {
      events.delete( handle )

      if ( events.size === 0 ) {
        window.removeEventListener( 'resize', onResize, true )
      }
    }
    // eslint-disable-next-line
  }, [] )

  return size
}
