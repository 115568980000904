import { TransitionOptions } from 'modules/shape-overlay'
import CosmicSymbolism from 'pages/cosmic-symbolism/cosmic-symbolism'
import Endpain from 'pages/endpain/endpain'
import Location from 'types/location'

export const cosmicTransition: TransitionOptions = {
  closeDirection: 'up',
  colors: [
    '#00c99b',
    '#ff0ea1',
    '#ffd392',
    '#ff3898',
    '#110046',
    '#32004a',
  ],
  component: CosmicSymbolism,
  direction: 'up',
  theme: 'random',
  url: '/cosmic',
}

export const endpainTransition: TransitionOptions = {
  closeDirection: 'right',
  colors: [
    'chocolate',
    'chocolate',
    '#6FCAB1',
    '#6FCAB1',
    'white',
    'white',
  ],
  component: Endpain,
  direction: 'left',
  theme: 'slingshot',
  url: '/endpain',
}

export const transitions = [ cosmicTransition, endpainTransition ].reduce( ( acc, item ) => ( { ...acc, [ item.url ]: item } ), {} ) as Record< Exclude<Location, '/' >, TransitionOptions>
export default transitions
