import * as React from 'react'

export const ContactUs: React.FC<{fill?: string}> = ( {fill = '#988f01'} ) => (
  <svg viewBox='0 0 1051 134' xmlns='http://www.w3.org/2000/svg' fillRule='evenodd' clipRule='evenodd' strokeLinejoin='round' strokeMiterlimit='1.414'>
    <path d='M112.677,96.382l-1.788,-0.795l-22.456,28.02c-4.571,1.987 -9.539,2.782 -15.501,2.782c-27.225,0 -49.88,-21.065 -49.88,-61.604c0,-41.335 19.475,-58.426 36.963,-58.426c19.873,0 33.187,9.936 46.502,36.566l3.179,-0.398l-1.589,-42.527l-2.186,0l-8.546,6.757c-7.551,-3.975 -19.077,-6.161 -29.212,-6.161c-39.348,0 -68.163,30.803 -68.163,67.567c0,39.149 27.822,64.983 67.765,64.983c18.084,0 28.418,-3.776 41.137,-10.532l3.775,-26.232Z'
    fill={fill} fillRule='nonzero' />
    <path d='M229.329,81.08c0,-28.616 -16.494,-51.867 -49.482,-51.867c-31.797,0 -50.278,23.251 -50.278,51.867c0,28.815 16.295,52.066 49.284,52.066c31.796,0 50.476,-23.251 50.476,-52.066Zm-20.27,0c0,32.591 -12.718,48.489 -28.219,48.489c-16.693,0 -31.001,-12.122 -31.001,-48.489c0,-36.367 12.52,-48.092 28.02,-48.092c16.693,0 31.2,15.7 31.2,48.092Z'
    fill={fill} fillRule='nonzero' />
    <path d='M255.76,123.011l-12.719,4.372l0,2.782l31.598,0l0,-2.782l-13.116,-4.372l0,-76.112l1.391,0l51.271,67.766l12.321,17.09l7.75,0l0,-92.407l12.719,-4.372l0,-2.782l-31.598,0l0,2.782l13.116,4.372l0,62.797l-1.391,0l-40.341,-53.259l-12.321,-16.692l-31.399,0l0,2.782l12.719,4.173l0,83.862Z'
    fill={fill} fillRule='nonzero' />
    <path d='M454.883,66.176l-5.962,-33.982l-86.048,0l-5.962,33.982l1.987,0.794l9.34,-13.115c8.347,-11.725 12.123,-15.898 20.866,-15.898l8.347,0l0,86.445l-15.699,2.981l0,2.782l49.681,0l0,-2.782l-15.699,-2.981l0,-86.445l6.756,0c8.943,0 12.719,4.173 21.065,15.898l9.34,13.115l1.988,-0.794Z'
    fill={fill} fillRule='nonzero' />
    <path d='M487.871,96.183l34.578,0l10.135,28.219l-14.507,2.782l0,2.981l46.303,0l0,-2.981l-12.718,-3.577l-35.175,-93.401l-9.737,0l-36.168,92.805l-10.93,4.173l0,2.981l30.008,0l0,-2.981l-11.725,-3.775l9.936,-27.226Zm32.591,-5.763l-30.405,0l15.103,-41.534l0.398,0l14.904,41.534Z'
    fill={fill} fillRule='nonzero' />
    <path d='M661.358,98.568l-1.788,-0.596l-20.866,27.821c-2.981,0.994 -6.36,1.59 -10.334,1.59c-21.065,0 -36.764,-17.488 -36.764,-47.893c0,-31.995 14.308,-45.11 28.616,-45.11c15.302,0 26.232,9.737 35.572,29.61l2.981,-0.199l-1.59,-34.976l-1.788,0l-6.558,5.565c-5.763,-2.981 -15.302,-5.167 -23.649,-5.167c-32.193,0 -54.053,24.045 -54.053,53.059c0,30.803 20.866,50.874 53.06,50.874c12.519,0 23.251,-2.583 33.187,-7.552l3.974,-27.026Z'
    fill={fill} fillRule='nonzero' />
    <path d='M772.446,66.176l-5.962,-33.982l-86.048,0l-5.962,33.982l1.987,0.794l9.341,-13.115c8.346,-11.725 12.122,-15.898 20.866,-15.898l8.346,0l0,86.445l-15.699,2.981l0,2.782l49.681,0l0,-2.782l-15.699,-2.981l0,-86.445l6.757,0c8.942,0 12.718,4.173 21.065,15.898l9.34,13.115l1.987,-0.794Z'
    fill={fill} fillRule='nonzero' />
    <path d='M940.965,12.321l0,72.734c0,21.064 -9.936,38.751 -33.783,38.751c-25.835,0 -38.752,-9.936 -38.752,-37.957l0,-75.317l19.674,-3.577l0,-3.378l-59.618,0l0,3.378l19.674,3.577l0,79.491c0,29.411 18.879,43.521 50.477,43.521c28.02,0 48.886,-18.681 48.886,-44.118l0,-77.105l15.699,-5.366l0,-3.378l-37.956,0l0,3.378l15.699,5.366Z'
    fill={fill} fillRule='nonzero' />
    <path d='M1050.26,101.747c0,-15.103 -8.943,-21.859 -19.674,-26.629c-6.16,-2.583 -12.718,-4.57 -18.481,-6.955c-8.943,-3.776 -15.898,-8.347 -15.898,-18.084c0,-9.34 7.154,-16.097 16.891,-16.097c10.93,0 18.482,7.75 30.207,27.225l3.577,-0.397l-2.385,-32.194l-2.981,0l-6.16,5.764c-5.763,-3.379 -11.924,-5.167 -19.674,-5.167c-16.892,0 -33.783,11.327 -33.783,29.808c0,12.719 7.154,19.277 16.295,23.848c5.763,2.782 12.321,4.769 18.283,7.154c10.731,4.173 19.674,9.141 19.674,21.263c0,9.937 -7.751,17.488 -19.873,17.488c-11.327,0 -21.065,-7.75 -32.591,-30.802l-3.378,0.397l1.987,35.175l2.782,0l6.359,-5.366c5.763,2.782 13.712,4.968 23.847,4.968c17.29,0 34.976,-11.725 34.976,-31.399Z'
    fill={fill} fillRule='nonzero' />
  </svg>
)

export default ContactUs
