import * as React from 'react'

interface LogoProps {
  color: string
  className?: string
}
export const Logo: React.FC<LogoProps> = ( { color, className } ) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 388.28 121.03' className={className}>
    <path d='M367.32,401.38a11.85,11.85,0,0,0-4-3.5,12.29,12.29,0,0,0-5.91-1.32,12.8,12.8,0,0,0-3.61.54,10.43,10.43,0,0,0-3.33,1.67,8.2,8.2,0,0,0-3.33,6.83,7.41,7.41,0,0,0,.88,3.81,8.39,8.39,0,0,0,2.35,2.55,13.61,13.61,0,0,0,3.33,1.73c1.25.46,2.55.91,3.91,1.36q2.52.82,5.07,1.77a18.07,18.07,0,0,1,4.59,2.48,12,12,0,0,1,3.33,3.88,12.25,12.25,0,0,1,1.29,5.95,12.72,12.72,0,0,1-5,10.54,15,15,0,0,1-5,2.51,20.11,20.11,0,0,1-5.68.82,22.14,22.14,0,0,1-4.35-.44,20.1,20.1,0,0,1-4.15-1.33A17.51,17.51,0,0,1,344,439a16.43,16.43,0,0,1-3-3.13l4.22-3.13a12.94,12.94,0,0,0,4.38,4.32,12.37,12.37,0,0,0,6.63,1.73,13,13,0,0,0,3.74-.57,10.7,10.7,0,0,0,3.44-1.77,9.87,9.87,0,0,0,2.51-2.93,9,9,0,0,0,0-8.22,8.83,8.83,0,0,0-2.62-2.79,15.75,15.75,0,0,0-3.74-1.87c-1.4-.5-2.87-1-4.42-1.5s-3.15-1.06-4.69-1.7A15.87,15.87,0,0,1,346.3,415a11.56,11.56,0,0,1-2.95-3.77,13.74,13.74,0,0,1,.27-11.5,12.54,12.54,0,0,1,3.64-4.14,15,15,0,0,1,5-2.38,20.1,20.1,0,0,1,5.37-.75,18.65,18.65,0,0,1,8.37,1.73,14.37,14.37,0,0,1,5.23,4.12Z'
    transform='translate(-341 -321.97)' fill={ color } />
    <polygon points='57.26 119.81 52.36 119.81 52.36 99.07 34.34 71.66 40.46 71.66 54.94 94.99 69.56 71.66 75.28 71.66 57.26 99.07 57.26 119.81'
    fill={ color } />
    <polygon points='106.49 111.51 106.56 111.51 122.67 71.66 130.15 71.66 130.15 119.81 125.26 119.81 125.26 77.99 125.12 77.99 107.71 119.81 105.13 119.81 87.72 77.99 87.58 77.99 87.58 119.81 82.69 119.81 82.69 71.66 90.17 71.66 106.49 111.51'
    fill={ color } />
    <path d='M489.85,437.56H499a24.49,24.49,0,0,0,5.07-.51,13,13,0,0,0,4.22-1.63,8.71,8.71,0,0,0,2.89-2.93,8.23,8.23,0,0,0,1.08-4.31c0-3.18-1.07-5.55-3.23-7.11s-5.2-2.35-9.14-2.35h-10Zm0-22.85h9.86a15.65,15.65,0,0,0,3.57-.41,9.86,9.86,0,0,0,3.3-1.39,7.41,7.41,0,0,0,2.41-2.62,8,8,0,0,0,.92-4,7.67,7.67,0,0,0-.92-3.91,7.58,7.58,0,0,0-2.48-2.62,10.58,10.58,0,0,0-3.67-1.46,22.2,22.2,0,0,0-4.49-.44h-8.5ZM485,393.63H499.3a26.74,26.74,0,0,1,5.51.58,15,15,0,0,1,5,2,10.8,10.8,0,0,1,3.6,3.78,11.66,11.66,0,0,1,1.4,6,10.14,10.14,0,0,1-2.31,6.63,10.88,10.88,0,0,1-6.19,3.71v.13a12.71,12.71,0,0,1,7.78,3.64,11.32,11.32,0,0,1,3.1,8.4,11.9,11.9,0,0,1-1.26,5.47,12,12,0,0,1-3.57,4.22,17.17,17.17,0,0,1-5.64,2.68,27.07,27.07,0,0,1-7.48,1H485Z'
    transform='translate(-341 -321.97)' fill={ color } />
    <path d='M571.66,417.7a23.83,23.83,0,0,0-1.36-8,20.2,20.2,0,0,0-3.95-6.7,19,19,0,0,0-6.29-4.59,21.26,21.26,0,0,0-16.66,0,19.15,19.15,0,0,0-6.29,4.59,20,20,0,0,0-3.94,6.7,24.36,24.36,0,0,0,0,16.05,19.54,19.54,0,0,0,3.94,6.66A19.22,19.22,0,0,0,543.4,437a21.26,21.26,0,0,0,16.66,0,19.11,19.11,0,0,0,6.29-4.56,19.72,19.72,0,0,0,3.95-6.66A23.88,23.88,0,0,0,571.66,417.7Zm5.16,0a26.38,26.38,0,0,1-1.9,10.14,23.89,23.89,0,0,1-13.23,13.26,27.06,27.06,0,0,1-19.92,0,23.89,23.89,0,0,1-13.23-13.26,28,28,0,0,1,0-20.27,23.89,23.89,0,0,1,13.23-13.26,27.06,27.06,0,0,1,19.92,0,23.89,23.89,0,0,1,13.23,13.26A26.33,26.33,0,0,1,576.82,417.7Z'
    transform='translate(-341 -321.97)' fill={ color } />
    <polygon points='251.94 115.45 274.38 115.45 274.38 119.81 247.04 119.81 247.04 71.66 251.94 71.66 251.94 115.45'
    fill={ color } />
    <rect x='282.74' y='71.66' width='4.9' height='48.14' fill={ color } />
    <path d='M665.77,401.38a11.81,11.81,0,0,0-4-3.5,12.36,12.36,0,0,0-5.92-1.32,12.68,12.68,0,0,0-3.6.54,10.38,10.38,0,0,0-3.34,1.67,8.46,8.46,0,0,0-2.41,2.82,8.33,8.33,0,0,0-.92,4,7.41,7.41,0,0,0,.89,3.81A8.36,8.36,0,0,0,648.8,412a13.79,13.79,0,0,0,3.33,1.73c1.25.46,2.55.91,3.91,1.36q2.52.82,5.07,1.77a18.07,18.07,0,0,1,4.59,2.48,12,12,0,0,1,3.33,3.88,12.25,12.25,0,0,1,1.29,5.95,13.07,13.07,0,0,1-1.39,6.22,13.24,13.24,0,0,1-3.6,4.32,15.09,15.09,0,0,1-5,2.51,20.09,20.09,0,0,1-5.67.82,22.24,22.24,0,0,1-4.36-.44,19.82,19.82,0,0,1-4.14-1.33,17.33,17.33,0,0,1-3.68-2.24,16.43,16.43,0,0,1-3-3.13l4.22-3.13a12.94,12.94,0,0,0,4.38,4.32,12.37,12.37,0,0,0,6.63,1.73,13,13,0,0,0,3.74-.57,10.7,10.7,0,0,0,3.44-1.77,9.73,9.73,0,0,0,2.51-2.93,9,9,0,0,0,0-8.22,8.79,8.79,0,0,0-2.61-2.79,16.14,16.14,0,0,0-3.74-1.87c-1.41-.5-2.88-1-4.42-1.5s-3.15-1.06-4.7-1.7a15.65,15.65,0,0,1-4.14-2.48,11.59,11.59,0,0,1-3-3.77,13.84,13.84,0,0,1,.27-11.5,12.54,12.54,0,0,1,3.64-4.14,15,15,0,0,1,5-2.38,20.16,20.16,0,0,1,5.37-.75,18.62,18.62,0,0,1,8.37,1.73,14.37,14.37,0,0,1,5.23,4.12Z'
    transform='translate(-341 -321.97)' fill={ color } />
    <polygon points='364.62 111.51 364.68 111.51 380.8 71.66 388.28 71.66 388.28 119.81 383.38 119.81 383.38 77.99 383.25 77.99 365.84 119.81 363.26 119.81 345.85 77.99 345.71 77.99 345.71 119.81 340.82 119.81 340.82 71.66 348.3 71.66 364.62 111.51'
    fill={ color } />
    <path d='M408.89,363.89c-5,6.68-11.85,10.5-19.42,10.5-12.45,0-23.4-11.1-23.4-23.55,0-12.82,10.95-23.77,23.32-23.77a23.48,23.48,0,0,1,19.28,10.12h6.22C410.47,328,399.74,322,389.47,322a28.69,28.69,0,1,0,.07,57.38c10.5,0,20.18-5.85,25.58-15.53Z'
    transform='translate(-341 -321.97)' fill={ color } />
    <path d='M482.92,350.77a23.86,23.86,0,0,1-23.85,23.62c-12.9,0-23.78-10.87-23.78-23.4,0-12.9,10.58-24,23.71-24A24,24,0,0,1,482.92,350.77Zm5.55-.08c0-15.82-13.27-28.72-29.4-28.72a29.16,29.16,0,0,0-29.33,29c0,16.13,14.18,28.43,29.33,28.43C475.2,379.42,488.47,366.37,488.47,350.69Z'
    transform='translate(-341 -321.97)' fill={ color } />
    <path d='M534.07,337A15,15,0,0,0,518.7,322c-8.55,0-15.6,6.15-15.6,14.92,0,10.13,7.57,12.75,15.52,15,7.28,2.1,11.25,5.48,11.25,11.48a11,11,0,0,1-11.17,11c-6.68,0-11.18-4.35-11.55-11.47H501.6c.14,9.9,7.27,16.5,17.1,16.5a16.41,16.41,0,0,0,16.72-16.28c0-7.5-4-13.72-16-16.8-7.88-2-10.72-4.95-10.72-9.67A9.74,9.74,0,0,1,518.7,327,10,10,0,0,1,528.52,337Z'
    transform='translate(-341 -321.97)' fill={ color } />
    <polygon points='267.77 56.48 267.77 0.97 259.22 0.97 238.97 48.23 218.79 0.97 210.25 0.97 210.25 56.48 215.79 56.48 215.79 7.05 215.94 7.05 236.57 56.48 241.29 56.48 262.07 7.05 262.22 7.05 262.22 56.48 267.77 56.48'
    fill={ color } />
    <rect x='287.42' y='0.97' width='5.55' height='55.5' fill={ color } />
    <path d='M699.52,363.89c-5,6.68-11.85,10.5-19.42,10.5-12.46,0-23.4-11.1-23.4-23.55,0-12.82,10.94-23.77,23.32-23.77a23.48,23.48,0,0,1,19.28,10.12h6.22C701.1,328,690.37,322,680.1,322a28.69,28.69,0,1,0,.07,57.38c10.5,0,20.18-5.85,25.57-15.53Z'
    transform='translate(-341 -321.97)' fill={ color } />
  </svg>
)

export default Logo
