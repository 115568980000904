import Close from 'components/close/close'
// import ExternalLink from 'components/external-link/external-link'
// import Eye from 'components/eye/eye'
import { useMedia } from 'hooks/media'
import useTimeout from 'hooks/use-timeout'
import { phoneUpperBoundary } from 'models/breakpoints'
import { classes } from 'modules/helpers'
import { App } from 'modules/plant-drawer/app'
import * as React from 'react'
// import { ReactComponent as CloseSvg } from 'svg/close-overlay.svg'
// import { ReactComponent as PyramidSvg } from 'svg/pyramid.svg'
// import style from './cosmic-symbolism.module.scss'
import './endpain.scss'

export interface EndpainProps {
  close: ( event: React.MouseEvent<HTMLElement> ) => void
}

const fill = '#fff'
const introCopy = `
The Endpain platform explores modalities
for healing and managing pain beyond traditional
western medicine. We've had the pleaure of working
with the team in developing and supporting their platform.

We engineered unique full-stack solutions to meet the needs
of the team. From unique search engine algorithms to
custom software to integrate with various services.
`

// const techCopy = `
// Consisting of both a quick search and full content
// search, we honed a custom TF-IDF algorithim and built
// it directly into our database. This saves time re-index,
// simplifies deployment, improves user experience and
// saves costs.
// `

const TransformCopy = ( paragraphClass: string, text: string ) => {
  const paragraphs = text
    .split( /\n\n/ )
    .filter( ( i ) => i.trim().replace( /\n/, '' ) )
  const getAuxClass = ( index: number ): string => (
    index === 0
      ? paragraphClass + '__first'
      : index === paragraphs.length - 1
        ? paragraphClass + '__last'
        : ''
  )
  return (
    <>
      { paragraphs.map( ( content, index ) => <p key={ paragraphClass + `-${ index }`} className={ classes( paragraphClass, getAuxClass( index ) ) }>{ content }</p> ) }
    </>
  )
}

const LogoContainer = () => (
  <div className='ENDPAIN--logo-container'>
  <svg className='ENDPAIN--logo-left' viewBox='0 0 167 33' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' xmlSpace='preserve'>
    <g>
      <rect x='-9.355' y='31.365' width='179.813' height='4.454' style={{ fill }} />
      <path d='M37.91,31.365l-5.561,0l0,-18.788l5.561,18.788Zm41.939,-31.427l-0.297,0l0,31.427l-14.353,0c9.961,0 10.471,-7.482 10.471,-15.714c0,-8.23 -0.51,-15.713 -10.471,-15.713l14.65,0Zm-27.161,31.427l-5.048,0l0,-31.427l5.048,0l0,31.427Zm-28.401,0l-4.531,0l0,-6.69l-11.398,0l0,-6.251l10.286,0l0,-6.426l-10.286,0l0,-5.37l10.981,0l0,-6.69l4.948,0l0,31.427Zm38.734,-5.898l-1.715,0l0,-19.631l1.715,0c3.336,0 3.753,1.321 3.753,9.815c0,8.495 -0.417,9.816 -3.753,9.816Zm-23.443,-6.803l-5.376,-18.726l5.376,0l0,18.726Zm69.391,-18.726l-16.193,0c0,0 16.193,0 16.193,0Zm21.397,0l-10.555,0l10.555,0Zm13.505,0l-4.887,0l4.887,0Zm15.291,0l-5.376,0l5.376,0Z' style={ { fill } } />
    </g>
  </svg>
  <svg className='ENDPAIN--logo-right' viewBox='0 0 167 33' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' xmlSpace='preserve'>
    <g>
      <rect x='-9.355' y='31.365' width='179.813' height='4.454' style={{ fill }}/>
      <path d='M157.494,31.365l-5.561,0l0,-18.788l5.561,18.788Zm-13.623,0l-4.887,0l0,-31.427l4.887,0l0,31.427Zm-13.505,0l-2.493,0l-8.062,-31.427l10.555,0l0,31.427Zm-11.667,0l-8.618,0l0.88,-5.502l6.858,0l0.88,5.502Zm-17.793,0l-12.439,0l0,-11.972l3.012,0c6.996,0 10.842,-3.345 10.842,-9.772c0,-5.501 -2.966,-9.683 -9.545,-9.683l16.193,0l-8.063,31.427Zm15.894,-11.664l-4.82,0l2.364,-13.337l0.092,0l2.364,13.337Zm42.362,-1.037l-5.376,-18.726l5.376,0l0,18.726Zm-69.073,-5.169l-1.622,0l0,-7.659l1.622,0c2.178,0 3.336,1.233 3.336,3.829c0,2.641 -1.158,3.83 -3.336,3.83Zm-65.802,-13.495l-4.948,0l0,-0.062l4.948,0l0,0.062Zm15.291,0l-5.358,0l-0.018,-0.062l5.376,0l0,0.062Zm13.11,0l-5.048,0l0,-0.062l5.048,0l0,0.062Zm27.161,0l-13.147,0c-0.475,-0.041 -0.976,-0.062 -1.503,-0.062l14.65,0l0,0.062Z' style={ { fill: '#fff' } } />
    </g>
  </svg>
</div>
)

const Divider = () => (
  <div className='ENDPAIN--flower-content-divider'></div>
)

const Introduction: React.FC<{opacity: number}> = ( {opacity} ) => {
  return (
    <div className='ENDPAIN--flower-content-introduction-container' style={{ willChange: 'opacity', transition: 'opacity 1s ease', opacity }}>
      <div className='ENDPAIN--flower-content-introduction-copy'>
        { TransformCopy( 'ENDPAIN--flower-content-paragraph', introCopy ) }
      </div>
      {/* <svg viewBox='0 0 100 100' className='ENDPAIN--flower-content-curve'>
        <path d='M 0 0 S -1 100 100 100 L 0 100 L 0 0' style={{ fill: 'white' }} />
      </svg> */}
    </div>
  )
}

const Section: React.FC<{title: string, children: React.ReactChild , opacity: number }> = ( { title, children, opacity } ) => (
  <div className='ENDPAIN--flower-content-introduction-container' style={{ willChange: 'opacity', transition: 'opacity 1s', opacity }}>
    <div>
      <div className='ENDPAIN--technology'>{ title } </div>
      { children }
    </div>
  </div>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SectionWide: React.FC<{title: string, children: React.ReactChild , opacity: number }> = ( { title, children, opacity } ) => (
  <div className='ENDPAIN--section-wide' style={{ willChange: 'opacity', transition: 'opacity 1s', opacity }}>
    <div>
      <div className='ENDPAIN--technology'>{ title } </div>
      { children }
    </div>
  </div>
)

export const Endpain: React.FC<EndpainProps> = ( { close } ) => {
  const smallMobile = useMedia( { maxWidth: phoneUpperBoundary }, null )
  const appRef = React.useRef<HTMLDivElement>( null )
  const stageRef = React.useRef<SVGSVGElement>( null )
  const branchRef = React.useRef<SVGGElement>( null )
  const thornRef = React.useRef<SVGGElement>( null )
  const leafRef = React.useRef<SVGGElement>( null )
  const flowerRef = React.useRef<SVGGElement>( null )

  const [ opacity, setOpacity ] = React.useState( 0 )
  useTimeout( () => {
    setOpacity( 1 )
  }, 1200 )

  React.useEffect( () => {
    let app: { clearOld: () => void }
    if ( smallMobile !== null && appRef.current !== null && stageRef.current && branchRef.current && thornRef.current && leafRef.current && flowerRef.current ) {
      const action = () => app = new App( appRef.current as HTMLElement, smallMobile, {
        branch: branchRef.current as unknown as HTMLElement,
        flower: flowerRef.current as unknown as HTMLElement,
        leaf: leafRef.current as unknown as HTMLElement,
        stage: stageRef.current as unknown as HTMLElement,
        thorn: thornRef.current as unknown as HTMLElement,
      } )
      setTimeout( action, 1200 )
    }
    return () => app && app.clearOld()
  }, [ smallMobile ] )

  return (
    <div className='ENDPAIN--container'>
      <Close color='black' onClick={ close } className='CLOSE--upper-right' style={{ stroke: 'black' }} />
      <div className='ENDPAIN--case-study'>Case Study of</div>
      <div className='ENDPAIN--flower-content-container'>
        <div className='ENDPAIN--flower-container'>
            <div style={{ width: '100%' }} ref={ appRef } className='ENDPAIN--flower'>
            <svg ref={ stageRef } style={{ height: '100%', width: '100%', transition: 'opacity 1s', opacity }} xmlns='http://www.w3.org/2000/svg'>
                <g >
                  <g ref={ branchRef }></g>
                  <g ref={ thornRef }></g>
                  <g ref={ leafRef }></g>
                  <g ref={ flowerRef }></g>
                </g>
            </svg>
            </div>
        </div>
        <LogoContainer />
        <Divider />
        <Introduction { ...{ opacity } }/>
        <Section { ...{opacity } } title='Summary of Work'>
          <ul>
            <li>Architect and produce both a backend and middleware app to unify third-party services under the platform.</li>
            <li>Engineered a custom TF-IDF algorithm built into the database and a quick as-you-type search feature.</li>
            <li>Integration into a native iOS and Android app.</li>
            {/* <li>Listened to the team, provide feedback and solutions</li> */}
          </ul>
        </Section>
        {/* <Section { ...{opacity } } title='Sample Pages'>
          <ul style={{ pointerEvents: 'all' }}>
            <li><ExternalLink href='https://www.endpain.com/search?q=diabetes&sqa=f0845&f=sp'>Search Engine Page</ExternalLink></li>
            <li><ExternalLink href='https://www.endpain.com/tag-listing'>Browse Tags</ExternalLink></li>
            <li><ExternalLink href='https://www.endpain.com/stories-vox-pop-self-care--2fdc44e1a6c95b80053075a32c01344b5ee98b41'>Custom Audio Player</ExternalLink></li>
            <li><ExternalLink href='https://www.endpain.com/stories-earl-granville-the-weight-we-carry--e4a988ef1031588897617624ca5bafc998b11c18'>Video Page</ExternalLink></li>
          </ul>
        </Section> */}
      </div>

      {/* <div className='ENDPAIN--pyramid-container'>
        <PyramidSvg />
      </div> */}
    </div >
  )
}

// const fun = (
//   <div onClick={ close } style={{width: '25%'}}>
//     <Eye scale={ 2 } scalePointerOverride={ 2 } />
//   </div>
// )

export default Endpain
