// import { Power1, TweenMax } from 'gsap'
import { Position } from '../Position'
const firefoxBugCorrectionContainer = document.createElement( 'div' )
export class Thorn {

  private thorn: SVGPathElement

  constructor( stage: HTMLElement, position: Position, size: number ) {
    this.thorn = document.createElementNS( 'http://www.w3.org/2000/svg', 'path' )
    this.thorn.setAttribute( 'd', this.createThornPath( {x: 0, y: 0}, size ) )
    this.thorn.setAttribute( 'class', 'thorn' )
    this.thorn.style.fill = 'brown'
    this.thorn.style.stroke = 'none'

    const TweenMax = ( window as any ).TweenMax

    // order is important for FireFox
    firefoxBugCorrectionContainer.appendChild( this.thorn )
    TweenMax.set( this.thorn, {scale: 0, x: position.x, y: position.y, rotation: Math.random() * 360} )
    TweenMax.to( this.thorn, 5, {scale: 1} )
    stage.appendChild( this.thorn )

  }

  public clear() {
    this.thorn.remove()
  }

  private createThornPath( p: Position, w: number ): string {
    const path = `M ${p.x} ${p.y} Q ${p.x - w / 2} ${p.y}  ${p.x - w / 2} ${p.y + w / 4} L ${p.x} ${p.y + w * 2} L ${p.x + w / 2} ${p.y + w / 4} Q ${p.x + w / 2} ${p.y} ${p.x} ${p.y} Z`
    return path
  }

}
