import enter from './enter-space-shader'
import fly from './fly-sideways-shader'
import ShaderProps from './shader-props'

const options = { enter, fly }

export default ( props: ShaderProps ) => {
  return [ enter, fly ].map( ( fn ) => fn( props ) )
}
export const getShader = ( props: ShaderProps, shaderName: 'enter' | 'fly' ) => options[ shaderName ]( props )
