// import {useState} from './modules/hooks'
import Provider from 'context/app-context'
import Router from 'context/browser-router-provider'
// import Landing from 'pages/landing/landing'
import Scaffold from 'pages/scaffold/scaffold'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { Route } from 'react-router-dom'
import './index.scss'
import * as serviceWorker from './serviceWorker'
// tslint:disable-next-line:no-debugger

ReactDOM.render(
  <Router>
    <Provider value={{ location: '/', renders: 0 }}>
      <Route path='/' component={ Scaffold } />
    </Provider>
  </Router>
  , document.getElementById( 'root' ) )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
