
import * as browser from 'detect-browser'
import head from 'soultrain/lib/array/head'
import { evolve } from 'soultrain/lib/function/evolve'
// import identity from 'soultrain/lib/function/identity'
import { pipe } from 'soultrain/lib/function/pipe'
import { Maybe, nothing } from 'soultrain/lib/maybe'
// import { safeProp } from 'soultrain/lib/maybe-functions'
import { split, toLowerCase } from 'soultrain/lib/string'

const safeNotValue = ( value: unknown ) => <A extends {}>( item: A ): Maybe<A> => (
  ( item !== value )
    ? Maybe.of( item )
    : nothing
)

type StripAllNullValue< A extends object> = {
  [I in keyof Required<A>]: A[I] extends infer U
    ? U extends undefined | null
      ? never
      : U
    : never
}

const safeNotNullableProp = ( prop: string ) => <A extends object>( obj: A ) => (
    // tslint:disable-next-line:ter-computed-property-spacing
  ( obj as { [k in string] : unknown } )[ prop ] != null
    ? Maybe.of( obj )
    : nothing
)

const toNumber = ( x: string ): number => parseInt( x, 10 )

interface BrowserCheck {
  version: number
  name: string
  os: string
}

export const browserCheck = ( fn: ( object: BrowserCheck ) => boolean ) => (
  Maybe.from( browser.detect() )
    .chain( safeNotValue( false ) )
    .map( ( i ) => i as browser.BrowserInfo )
    .chain( safeNotNullableProp( 'name' ) )
    .chain( safeNotNullableProp( 'version' ) )
    .chain( safeNotNullableProp( 'os' ) )
    .map( ( i ) => i as StripAllNullValue<typeof i> )
    .map( evolve( {
      name: toLowerCase,
      os: toLowerCase,
      version: pipe(
        split( '.' ),
        head,
        toNumber,
      ),
    } ) )
    .map( fn )
    .joinOrValue( false )
)
