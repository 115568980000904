import styles from 'models/_variables-and-functions.module.scss'

const parsePx = ( style: Record<string, string> ) => ( variable: string ): number => {
  const normalized = style[ variable ].replace( /[\t\n\s ]+/g, '' )
  const noPx = normalized.toLowerCase().replace( 'px', '' )
  const digitOnly = noPx.replace( /[^\d]+/g, '' )
  if ( normalized === noPx ) {
    // tslint:disable-next-line:no-console
    console.warn( variable, 'is not expressed in px (pixels), assuming pixels' )
  }
  if ( digitOnly.length !== noPx.length ) {
    // tslint:disable-next-line:no-console
    console.warn( variable, 'has a non-compatible value' )
  }
  return parseInt( digitOnly, 10 )
}

const parseStyleVariable = parsePx( styles )

export const phoneUpperBoundary: number = parseStyleVariable( 'phone-upper-boundary' )
export const phoneWideUpperBoundary: number = parseStyleVariable( 'phone-wide-upper-boundary' )
export const phoneNarrowUpperBoundary: number = parseStyleVariable( 'phone-narrow-boundary' )
export const tabletPortraitUpperBoundary: number = parseStyleVariable( 'tablet-portrait-upper-boundary' )
export const tabletLandscapeUpperBoundary: number = parseStyleVariable( 'tablet-landscape-upper-boundary' )
