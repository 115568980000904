import { AppContext, State } from 'context/app-context'
import { RouterContext } from 'context/browser-router-provider'
import getImageAssets from 'models/image-assets'
import { browserCheck } from 'modules/browser-check'
import ShapeOverlays, { Direction, Theme, TransitionOptions } from 'modules/shape-overlay'
import Landing from 'pages/landing/landing'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
// import { trace } from 'soultrain/lib/logging'
import Location from 'types/location'
// import { AnimatedStripeClipsMemo } from './animated-stripe-clips'
import { Controls } from './Controls'
import styles from './scaffold.module.scss'
import './scaffold.scss'

// const skyImage = 'https://images-mawaruudfw.now.sh/sky-image.jpg'

const skyImage = getImageAssets().sky

const doFancy = browserCheck( ( { version, name, os } ) => (
    // evaluate cases
    ( name === 'chrome' && version >= 70 ) ? true :
    ( name === 'safari' && version >= 11 ) ? true :
    ( os === 'ios' && version >= 12 ) ? true :

    // default case
    false
  ),
)

interface Tuple<T extends any, L extends number> extends Array<T> {
  0: T
  length: L
}

const body = document.querySelector( 'body' ) as HTMLElement

const initialSlideStyle: React.CSSProperties = {
  // background: 'url(https://images.pexels.com/photos/415574/pexels-photo-415574.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  height: '100%',
  opacity: 0,
  overflow: 'scroll',
  // overflowScrolling: 'touch',
  pointerEvents: 'none',
  position: 'fixed',
  zIndex: 99,
  // tslint:disable-next-line:object-literal-sort-keys
  WebkitOverflowScrolling: 'touch',
  width: '100vw',
}

type ContentAnimations = (
  Record<
  'open'|'close',
      Record<Direction,
      React.CSSProperties
    >
  >
)

// const contentDelay = .2
const contentDelay = .2
const openningOverlayDelay = .8
const verticalDistance = 60
const noAnimationPosition: ContentAnimations = {
  close: {
    down: {},
    left: {},
    right: {},
    up: {},
  },
  open: {
    down: {
      left: 0,
      top: - verticalDistance,
      transition: `${ 0 }s all ease-out`,
    },
    left: {
      left: ( verticalDistance * 2 ),
      top: 0,
      transition: `${ 0 }s all ease-out`,
    },
    right: {
      left: - ( verticalDistance * 2 ),
      top: 0,
      transition: `${ 0 }s all ease-out`,
    },
    up: {
      left: 0,
      top: verticalDistance,
      transition: `${ 0 }s all ease-out`,
    },
  },
}

const innerAnimations: ContentAnimations = {
  close: {
    down: {
      left: 0,
      top: verticalDistance,
      transition: `${ contentDelay }s all ease-in`,
    },
    left: {
      left: - ( verticalDistance * 2 ),
      top: 0,
      transition: `${ contentDelay }s all ease-in`,
    },
    right: {
      left: verticalDistance * 2,
      top: 0,
      transition: `${ contentDelay }s all ease-in`,
    },
    up: {
      left: 0,
      top: - verticalDistance,
      transition: `${ contentDelay }s all ease-in`,
    },
  },
  open: {
    down: {
      left: 0,
      top: 0,
      transition: `${ contentDelay }s all ease-out`,
    },
    left: {
      left: 0,
      top: 0,
      transition: `${ contentDelay }s all ease-out`,
    },
    right: {
      left: 0,
      top: 0,
      transition: `${ contentDelay }s all ease-out`,
    },
    up: {
      left: 0,
      top: 0,
      transition: `${ contentDelay }s all ease-out`,
    },
  },
}

const closeScale: Record< Theme, number > = {
  ai: 1,
  mountain: 1,
  random: 2,
  slingshot: 1,
  water: 0,
}

const noChange = {
  ai: 1,
  mountain: 1,
  random: 1,
  slingshot: 1,
  water: 1,
}

const openScale = {
  down: {
    ...noChange,
  },
  left: {
    ...noChange,
    random: 1.38,
    slingshot: 1.35,
  },
  right: {
    ...noChange,
    random: 1.3,
    slingshot: 1.05,
  },
  up: {
    ...noChange,
    random: 1.5,
  },
}

const animationSetup = (
    setAppState: React.Dispatch<Partial<State>>,
    routerHistory: RouteComponentProps['history'],
    svgElement: SVGSVGElement,
    setPanel: React.Dispatch<React.SetStateAction<( _: {} ) => JSX.Element>>,
    setControls: React.Dispatch<React.SetStateAction<Controls | null>> ,
    setSlideInnerStyles: React.Dispatch<React.SetStateAction<React.CSSProperties>>,
    setSlideOuterStyles: React.Dispatch<React.SetStateAction<React.CSSProperties>>,
    // setTransitionColors: ( [ a, b, c, d, e, f ]: [ string, string, string, string, string, string ] ) => void,
    setTransitionOptions: React.Dispatch<React.SetStateAction< TransitionOptions >>,
  ) => {
  const so = new ShapeOverlays( svgElement as SVGElement )
  const controls: Controls = {
    close: ( options ) => ( event ) => {
      routerHistory.push( '' )
      setAppState( { location: '/' } )
      setSlideInnerStyles( { ...noAnimationPosition.close[ options.closeDirection ]} )

      setTimeout( () => {
        so.close( options )
      }, contentDelay * 1000 * closeScale[ options.theme ] )

      // unmount component by replacing the panel with an empty partial
      setTimeout( () => {
        setPanel( () => () => <></> )
      }, contentDelay * 1000 * closeScale[ options.theme ] + 1000 )

      // 932

      setSlideInnerStyles( { ...innerAnimations.close[ options.closeDirection ] } )
      setSlideOuterStyles( { ...initialSlideStyle,  transition: `${ contentDelay }s all ease-in`, pointerEvents: 'none', opacity: 0} )
      Object.assign( body.style, {overflow: 'auto'} )
    },
    open: ( options, jsx ) => ( event ) => {
      routerHistory.push( options.url, {fo: 'bar'} )
      setAppState( { location: options.url } )
      // setTransitionOptions( [ '#dce3f1', '#dce3f1', '#2e578c', '#2e578c', '#efcb7b', '#efcb7b' ] )
      // setTransitionColors( [ '#87BBFF', '#87BBFF', '#2e578c', '#2e578c', '#FFD587', '#FFD587' ] )
      // setTransitionColors( [ '#B66C85', '#B66C85', '#4ACBED', '#4ACBED', '#E9E3A2', '#E9E3A2' ] )
      setTransitionOptions( options )
      setSlideInnerStyles( { ...noAnimationPosition.open[ options.direction ]} )
      setTimeout( () => {
        setSlideInnerStyles( { ...innerAnimations.open[ options.direction ]} )
        setSlideOuterStyles( { ...initialSlideStyle,  transition: `${ contentDelay }s all ease-out`, pointerEvents: 'auto', opacity: 1} )
        Object.assign( body.style, {overflow: 'hidden'} )

      }, openningOverlayDelay * 1000 * ( openScale[ options.direction ][ options.theme ] || 1 ) )

      setPanel( () => jsx )
      so.open( options )
    },
  }
  setControls( controls )
}

let swishCount = 0
let renders = 0
const Liquid = ( ) => {
  renders = renders + 1
  const { history } = React.useContext( RouterContext )

  history.listen( ( location, action ) => {
    // location is an object like window.location
    // console.log( action, location.pathname, location.state )
  } )
  const [ appState, setAppState ] = React.useContext( AppContext )

  // handle direct links and back click "pop"
  const goTo: null | Location = (
    history.location.pathname === '/cosmic' ||
    history.location.pathname === '/endpain' ||
    history.location.pathname === '/'
  ) ? history.action === 'POP'
        ? appState.location === history.location.pathname
          ? null
          : history.location.pathname
        : null
    : null

  const svgRef = React.useRef<SVGSVGElement>( null )
  const slideAnimation = React.useRef<HTMLElement>( null )
  // const [ { colors, direction, theme }, setTransitionOptions ] = React.useState( {} as TransitionOptions )
  const [ { colors, theme }, setTransitionOptions ] = React.useState( {} as TransitionOptions )

  // eslint-disable-next-line no-empty-pattern
  const [ SlideInnerContent, setSlideInnerContent ] = React.useState( ( ) => ( {} ) => <></> )
  const [ slideInnerStyles, setSlideInnerStyles ] = React.useState< React.CSSProperties >( {} )
  const [ slideOuterStyles, setSlideOuterStyles ] = React.useState < React.CSSProperties > ( initialSlideStyle )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ isReady, setIsReady ] = React.useState( false )
  const [ controls, setControls ] = React.useState < Controls | null> ( null )
  let sideEffect = () => {
    setIsReady( true )
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ignore = svgRef.current && slideAnimation.current && animationSetup(
      setAppState,
      history,
      svgRef.current,
      setSlideInnerContent,
      setControls,
      setSlideInnerStyles,
      setSlideOuterStyles,
      setTransitionOptions as ( options: React.SetStateAction<TransitionOptions> ) => void,
    )
  }
  React.useEffect( sideEffect, [] )

  const getArrayItemOffset = <A extends unknown>( max: number, arr: A[] ) => ( item: number ): A => arr[ Math.floor( ( arr.length / max ) * item ) ]
  const getColor = colors ? getArrayItemOffset( 6, colors as unknown as string[] ) : ( item: number ) => '#000'
  const incSwishCount = () => swishCount++
  const showStars = () => doFancy && theme === 'random' && ( incSwishCount() > 2 && appState.renders > 1 ) && window.innerWidth <= 1680
  return (
    <>
      <div className={ styles.brah }>
        { controls !== null ? <Landing handleTransition={ controls } goTo={ goTo } /> : <></> }
      </div>
      <div style={ {...slideOuterStyles, color: 'black', zIndex: 4}}>
        <div
          className='stage'
          ref={ slideAnimation as React.RefObject<HTMLDivElement> }
          style={{  position: 'relative', willChange: 'left, top', transform: 'translateZ(0)', ...slideInnerStyles}}>
            <SlideInnerContent />
        </div>
      </div>
      <svg ref={ svgRef } className='shape-overlays' viewBox='0 0 100 100' preserveAspectRatio='none' style={{position: 'fixed', zIndex: 3, overflow: 'scroll'}}>
        <defs>
          <linearGradient id='gradient1' x1='0%' y1='0%' x2='0%' y2='100%'>
            <stop offset='0%'   stopColor={ getColor( 0 ) } />
            <stop offset='100%' stopColor={ getColor( 1 ) } />
          </linearGradient>
          <linearGradient id='gradient2' x1='0%' y1='0%' x2='0%' y2='100%'>
            <stop offset='0%'   stopColor={ getColor( 2 ) } />
            <stop offset='100%' stopColor={ getColor( 3 ) } />
          </linearGradient>
          <linearGradient id='gradient3' x1='0%' y1='0%' x2='0%' y2='100%'>
            <stop offset='0%'   stopColor={ getColor( 4 ) } />
            <stop offset='100%' stopColor={ getColor( 5 ) } />
          </linearGradient>
          <pattern id='spaceSvgImage' patternUnits='userSpaceOnUse' width='100%' height='100%'>
            <image
                xlinkHref={ skyImage } x='-30' y='-30'
                width='150'
                height='168' />
          </pattern>
        </defs>
        <path className='shape-overlays__path shape-overlays__path-1'></path>
        { false && showStars()  && <>
            <path className='shape-overlays__path' style={{fill: 'url(#spaceSvgImage)' }}></path>
            <path className='shape-overlays__path' style={{fill: 'url(#spaceSvgImage)' }}></path>
        </> }
        <path className='shape-overlays__path shape-overlays__path-2'></path>
        <path className='shape-overlays__path shape-overlays__path-3'></path>
      </svg>
    </>
  )
}

export default Liquid
