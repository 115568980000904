import ease from './ease'
// tslint:disable-next-line:ordered-imports
import { timesWithRepeat_ as loop } from 'soultrain/lib/flow'
import pipeline from 'soultrain/lib/function/pipeline'
import Location from 'types/location'

// tslint:disable-next-line:no-empty
const doNothing = (): void => {}
//
// these easing functions are based on the code of glsl-easing module.
// https://github.com/glslify/glsl-easings
//

const startLeft = ( point: number, isOpened: boolean ) => (
  isOpened
    ? `M 0 0 H ${point}`
    : `M ${point} 0`
)

const startRight = startLeft

const startUp = ( point: number, isOpened: boolean ) => (
  isOpened
    ? `M 0 0 V ${point}`
    : `M 0 ${point}`
)

const startDown = startUp

const endLeft = ( isOpened: boolean ): string => (
  isOpened ? `H 100 V 0` : `H 0 V 0`
)

const endRight = ( isOpened: boolean ): string => (
  isOpened ? `H 0 V 0` : `H 100 V 0`
)

const endUp = ( isOpened: boolean ): string => (
  isOpened ? `V 100 H 0` : `V 0 H 0`
)

const endDown = ( isOpened: boolean ): string => (
  isOpened ? `V 0 H 0` : `V 100 H 0`
)

// thisEase(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1)) * 100
// ease.cubicInOut

const directionEquation = ( easeFn: ( i: number ) => ( n: number ) => number , time: number, delayPoints: number[], duration: number, direction: Direction ) => ( i: number ) => ( {
  down: easeFn( i )( Math.min( Math.max( time - delayPoints[ i ], 0 ) / duration, 1 ) ) * 100,
  left: ( 1 - easeFn( i )( Math.min( Math.max( time - delayPoints[ i ], 0 ) / duration, 1 ) ) ) * 100,
  right: easeFn( i )( Math.min( Math.max( time - delayPoints[ i ], 0 ) / duration, 1 ) ) * 100,
  up: ( 1 - easeFn( i )( Math.min( Math.max( time - delayPoints[ i ], 0 ) / duration, 1 ) ) ) * 100,
} )[            direction ]

// const progressLeft = (  )
type Point = [ number , number ]
interface Curve {
  controlPoint1: Point
  controlPoint2: Point
  endPoint: Point
}
const curve = ( {controlPoint1: [ c1x, c1y ], controlPoint2: [ c2x, c2y ], endPoint: [ ex, ey ]}: Curve ) => (
  `C ${ c1x } ${ c1y }, ${ c2x } ${ c2y }, ${ ex } ${ ey }`
)

interface ProgressParams {
  startPoint: number
  endPoint: number
  startControlPointComputed: number
  endPointComputed: number
}

type Progress = ( params: ProgressParams ) => string

const progressLeft: Progress = ( {
  startPoint,
  endPoint,
  startControlPointComputed,
  endPointComputed,
} ) => (
  curve( {
    controlPoint1: [ startPoint, startControlPointComputed ],
    controlPoint2: [ endPoint, startControlPointComputed ],
    endPoint:      [ endPoint, endPointComputed ],
  } )
)

const progressRight = progressLeft

const progressUp: Progress = ( {
  startPoint,
  endPoint,
  startControlPointComputed,
  endPointComputed,
} ) => (
  curve( {
    controlPoint1: [ startPoint, startControlPointComputed ].reverse() as Point,
    controlPoint2: [ endPoint, startControlPointComputed ].reverse() as Point,
    endPoint:      [ endPoint, endPointComputed ].reverse() as Point,
  } )
)

const progressDown = progressUp

const pathProgress = ( points: number[], progress: Progress ) => loop(
  // total iterations
  points.length - 1,

  // iterations
  ( i, total ) => {
    const endPointComputed = ( i + 1 ) / ( total ) * 100
    const startControlPointComputed = endPointComputed - ( 1 / ( total ) * 100 ) / 2
    const startPoint = points[ i ]
    const endPoint = points[ i + 1 ]
    return progress( {
      endPoint,
      endPointComputed,
      startControlPointComputed,
      startPoint,
    } )
  } ).join( '' )

const directionFns = {
  down: [ startDown, progressDown, endDown ],
  left: [ startLeft, progressLeft, endLeft ],
  right: [ startRight, progressRight, endRight ],
  up: [ startUp, progressUp, endUp ],
}

export type Direction = keyof ( typeof directionFns )
type DirectionFns = [typeof startLeft, typeof progressLeft, typeof endLeft]

const getDirectionFns = ( direction: Direction ): DirectionFns =>
  directionFns[ direction ] as DirectionFns

const getPoints = ( easeFn: ( i: number ) => ( n: number ) => number, time: number, numPoints: number , delayPoints: number[], duration: number, direction: Direction ) => (
  loop(
    numPoints,
    // ( i ) => (1 - ease.cubicInOut(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1))) * 100
    directionEquation(
      easeFn,
      time,
      delayPoints,
      duration,
      direction,
    ),
  )
)

const rangeWater = () => Math.random() * Math.PI * 2
const rangeMountain = 4 * Math.random() + 6

const pointDelays = {
  ai: ( _: number, _2: number ) => ( i: number ) => 0,
  mountain: ( delayPointsMax: number, numPoints: number ) => ( i: number ) => pipeline(
    i / ( numPoints - 1 ) * Math.PI,
    ( radian ) => ( Math.sin( -radian ) + Math.sin( -radian * rangeMountain ) + 2 ) / 4 * delayPointsMax,
  ),
  random: ( delayPointsMax: number, numPoints: number ) => ( i: number ) => Math.random() * delayPointsMax,
  water: ( delayPointsMax: number, numPoints: number ) => ( i: number ) => pipeline(
    ( i / ( numPoints - 1 ) ) * Math.PI * 2,
    ( radian ) => ( Math.sin( radian + rangeWater() ) + 1 ) / 2 * delayPointsMax,
  ),
}

type PointDelayStyles = keyof ( typeof pointDelays )
const getPointDelay = ( style: PointDelayStyles, delayPointsMax: number, numPoints: number )  => {
  return pointDelays[ style ]( delayPointsMax, numPoints ) as ( ( i: number ) => number )

}

export interface ThemeOptions {
  alternatingEase: number[]
  delayPerPath: number
  delayPointsMax: number
  duration: number
  numPoints: number
  pointDelayStyle: PointDelayStyles
  slingshotEase: boolean
}

const _themes = {
  ai: {
    alternatingEase: [ 1 ],
    delayPerPath: 200,
    delayPointsMax: 0,
    duration: 600,
    numPoints: 2,
    pointDelayStyle: 'ai',
    slingshotEase: false,
  },
  mountain: {
    alternatingEase: null,
    delayPerPath: 100,
    delayPointsMax: 300,
    duration: 600,
    numPoints: 18,
    pointDelayStyle: 'mountain',
    slingshotEase: false,
  },
  random: {
    alternatingEase: null,
    delayPerPath: 250 / 3,
    delayPointsMax: 300,
    duration: 900,
    numPoints: 10,
    pointDelayStyle: 'random',
    slingshotEase: false,
  },
  water: {
    alternatingEase: null,
    delayPerPath: 70,
    delayPointsMax: 180,
    duration: 800,
    numPoints: 4,
    pointDelayStyle: 'water',
    slingshotEase: false,
  },

  slingshot: {
    alternatingEase: null,
    delayPerPath: 60,
    delayPointsMax: 0,
    duration: 1000,
    numPoints: 4,
    pointDelayStyle: 'ai',
    slingshotEase: true,
  },
}

const themes = _themes as unknown as Record<keyof ( typeof _themes ), ThemeOptions>
export type Theme =  keyof ( typeof themes )

export interface TransitionPage {
  close: ( event: React.MouseEvent<HTMLElement> ) => void
}

export interface TransitionOptions {
  component: React.FC<TransitionPage>
  url: Location
  direction: Direction
  closeDirection: Direction
  theme: Theme
  colors: { 0: string, length: 3 } | { 0: string, length: 6 }
}

export default class ShapeOverlays {
  alternatingEase!: number[]
  // alternatingTransition: boolean
  delayPerPath!: number
  delayPointsArray: number[]
  delayPointsMax!: number
  // direction: Direction
  duration!: number
  elm: SVGElement | HTMLElement
  isAnimating: boolean
  isOpened: boolean
  numPoints!: number
  path: NodeListOf<SVGPathElement>
  pointDelayStyle!: keyof ( typeof pointDelays )
  slingshotEase!: boolean
  timeStart: number
  // theme: Theme

  constructor( elm: SVGElement | HTMLElement ) {
    // theme: Theme = 'slingshot', direction: Direction = 'left', alternatingTransition = true ) {
    this.elm = elm
    this.path = elm.querySelectorAll( 'path' )
    this.timeStart = Date.now()
    // this.direction = direction
    // this.alternatingTransition = alternatingTransition
    this.delayPointsArray = []
    this.isOpened = false
    this.isAnimating = false
    // this.theme = theme
    // Object.assign( this, themes[ theme ] )
    // this.numPoints = 10;
    // this.duration = 900;
    // this.delayPointsMax = 300;
    // this.delayPerPath = 250 / 3;
    // this.alternatingEase = null
    // this.slingshotEase = false
    // this.pointDelayStyle = 'random'
  }
  // toggle() {

  //   if ( this.isOpened === false ) {
  //     return this.open()
  //   } else {
  //     return this.close()
  //   }
  // }
  open( {direction, theme}: TransitionOptions ) {
    Object.assign( this, themes[ theme ] )
    this.path = this.elm.querySelectorAll( 'path' )

    this.isAnimating = true
    this.delayPointsArray = loop(
      this.numPoints,
      getPointDelay( this.pointDelayStyle, this.delayPointsMax, this.numPoints ),
    )
    this.isOpened = true
    this.elm.classList.add( 'is-opened' )
    this.timeStart = Date.now()

    return this.renderLoop( direction )
    // return this.asyncRenderLoop();
  }
  close( {direction, theme}: TransitionOptions ) {
    Object.assign( this, themes[ theme ] )
    this.path = this.elm.querySelectorAll( 'path' )

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getAlternateDirection = ( _direction: Direction ): Direction => ( {
      down: 'up',
      left: 'right',
      right: 'left',
      up: 'down',
    } )[ _direction ] as Direction

    // const direction = this.alternatingTransition ? getAlternateDirection( this.direction ) : this.direction
    this.isAnimating = true
    this.delayPointsArray = loop(
      this.numPoints,
      getPointDelay( this.pointDelayStyle, this.delayPointsMax, this.numPoints ),
    )
    this.isOpened = false
    this.elm.classList.remove( 'is-opened' )
    this.timeStart = Date.now()

    return this.renderLoop( direction )
    // return this.asyncRenderLoop();
  }
  updatePath( direction: Direction, time: number ) {

    const getPath = ( isOpened: boolean, _time: number, _direction: Direction ): [string, string, string] => {
      const alternatingEase = ( alternateOn?: number[] ) => ( i: number ) => (
        alternateOn == null
          ? ease.cubicInOut
          : this.isOpened
            ? ( alternateOn.indexOf( i ) >= 0 )
              ? ease.cubicOut
              : ease.cubicInOut
            : ( alternateOn.indexOf( i ) >= 0 )
              ? ease.cubicInOut
              : ease.cubicOut
      )
      const slingshotEase = ( _?: number[] ) => ( i: number ) => (
        ( i % 2 === 1 ) ? ease.sineOut : ease.exponentialInOut
      )
      const easeFn = this.slingshotEase ? slingshotEase : alternatingEase
      const points = getPoints( easeFn( this.alternatingEase ), _time, this.numPoints, this.delayPointsArray, this.duration, _direction )
      const [ start, progress, end ] = getDirectionFns( _direction )
      return [ start( points[ 0 ], isOpened ), pathProgress( points, progress ), end( isOpened ) ]
    }

    return getPath( this.isOpened, time, direction ).join( '' )

  }
  render( direction: Direction ) {
    if ( this.isOpened ) {
      for ( let i = 0; i < this.path.length; i++ ) {
        this.path[ i ].setAttribute( 'd', this.updatePath( direction, Date.now() - ( this.timeStart + this.delayPerPath * i ) ) )
      }
    } else {
      for ( let i = 0; i < this.path.length; i++ ) {
        this.path[ i ].setAttribute( 'd', this.updatePath( direction, Date.now() - ( this.timeStart + this.delayPerPath * ( this.path.length - i - 1 ) ) ) )
      }
    }
  }
  renderLoop( direction: Direction, callback: () => void = doNothing ) {
    this.render( direction )
    if ( Date.now() - this.timeStart < this.duration + this.delayPerPath * ( this.path.length - 1 ) + this.delayPointsMax ) {
      requestAnimationFrame( () => {
        this.renderLoop( direction, callback )
      } )
    } else {
      this.isAnimating = false
      callback()
    }
  }
  // async asyncRenderLoop() {
  //   return new Promise((resolve,reject) => {
  //     this.renderLoop(resolve)
  //   })
  // }
}
