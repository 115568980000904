import * as React from 'react'
// import Fade from './fade'
import Liquid from './liquid'

export default () => {

  return (
    <>
      <Liquid />
    </>
  )
}
