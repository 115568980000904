import * as React from 'react'

export const PropsToProvider =  <T, >( Context: React.Context<T> ) => ( props: T & { children: React.ReactChild } ) => {
  const { children } = props
  const _propsShallowCopy =  { ...props }
  delete _propsShallowCopy.children

  const propsWithoutChildren = _propsShallowCopy
  return (
    <Context.Provider value={ propsWithoutChildren } >
      { children }
    </Context.Provider>
  )
}

export default PropsToProvider
