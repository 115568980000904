import Close from 'components/close/close'
// import Eye from 'components/eye/eye'
import useTimeout from 'hooks/use-timeout'
import { useWindowSize } from 'hooks/window-resize'
// import { phoneUpperBoundary } from 'models/breakpoints'
import getImageAssets from 'models/image-assets'
import { browserCheck } from 'modules/browser-check'
import * as React from 'react'
import ShaderToy from 'shadertoy-react'
// import { ReactComponent as CloseSvg } from 'svg/close-overlay.svg'
import Logo from './cosmic-symbolism-logo'
// import style from './cosmic-symbolism.module.scss'
import './cosmic-symbolism.scss'
import { getShader } from './shaders/get-shader'
import { TriangleImage } from './triangle-image'
// const fill = 'white'
export interface CosmicSymbolismProps {
  close: ( event: React.MouseEvent<HTMLElement> ) => void
}

const iOS =  browserCheck( ( { version, name, os } ) => (
    // evaluate cases
    os === 'ios' ? true : false
  ),
)

const _precision = iOS ? 'highp' : 'highp'
// const _speed = iOS ? 0.25 : .02
const _speed = iOS ? 0.25 : 0.25

// const foo = ( a: number ) => 'asdf'
// foo( 'asdf' )

const fragmentShader = getShader( { speed: _speed}, 'enter' )

export const CosmicSymbolism: React.FC<CosmicSymbolismProps> = ( { close } ) => {
  const { width, height } = useWindowSize()
  const [ opacity, setOpacity ] = React.useState( 0 )
  const [ visibility, setVisibility ] = React.useState< 'visible' | 'hidden'>( 'visible' )
  // eslint-disable-next-line no-empty-pattern
  const [ ShaderComponent, setShaderComponent ] = React.useState( () => ( {} ) => <></> )

  useTimeout(
    () => {
      setOpacity( 1 )
      setVisibility( 'visible' )
      setShaderComponent( () => () => <ShaderToy fs={ fragmentShader } devicePixelRatio={ 1 } precision={ _precision }/> )
    },
    1500,
  )
  React.useEffect( () => {
    // handle unmount on return value
    return () => {
      setVisibility( 'hidden' )
      setShaderComponent( () => () => <></> )
    }
  }, [] )

  return (
    <div className='COSMIC--container' style={{ height }}>
      <div style={{
        height,
        opacity,
        transition: 'opacity .5s',
        visibility,
        width,

      }}>
      {
/*
ShaderToyProps
fs -- A string containing your fragment shader.
textures -- An array of textures objects following that structure {url: ... , minFilter: , magFilter: , wrapS: ,wrapT: } the format supported are (.jpg, .jpeg, .png, .bmp) for images, and (.mp4, .3gp, .webm, .ogv) for videos. Textures needs to be squared otherwise they will be automatically squared for you. minFilter, magFilter, wrapS, wrapT are optionals, by default their values are set to {url: ... , minFilter: LinearMipMapLinearFilter, magFilter: LinearFilter, wrapS: ReapeatWrapping, wrapT: ReapeatWrapping}.
uniforms -- An object containing uniforms objects following that structure { uTest: {type: , value: }, uTest2: {type: , value: }, uTest3: {type: , value: } ... }. To know more about the supported types please refer to the custom uniforms section.
clearColor -- An array [red, green, blue, alpha] Specifies the color values used when clearing color buffers method of the WebGL API by default [0, 0, 0, 1].
precision -- GLSL Precision qualifier, by default highp, it can be lowp, mediump, highp depending on how much precision the GPU uses when calculating floats.
devicePixelRatio -- A value passed to set the pixel density of the canvas. By default 1.
contextAttributes -- To customize your WebGL context attributes.
style -- Pass a React Inline style to customize the style of your canvas.
onDoneLoadingTextures -- Callback called once all textures are done being loaded, usefull when you want to wait for your shader to have all the needed texture before seeing it on screen. Using that callback you could for example simply fade the canvas in using css.
lerp -- A value in between 0 - 1 used to lerp the mouse position in your fragment shader.
      */}
        <ShaderComponent />

        <div className='COSMIC--content' style={{  WebkitOverflowScrolling: 'touch' }}>
        <Close color='white' onClick={ ( event ) => {
          setOpacity( 0 )
          setTimeout( () => setShaderComponent( () => () => <></> ), 500 )
          close( event )
        } } className='CLOSE--upper-right'  />
          <div className='PAGE--container'>
            <div className='COSMIC--case-study'>Case Study of</div>
            <Logo color='#fff' className='COSMIC--logo' />
            {/* <div className='COSMIC--copy'>
              <span>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci ratione pariatur necessitatibus rerum quaerat, dolorem quasi debitis vero recusandae accusantium eum excepturi illo ipsam quam ea! Quisquam sunt sequi animi!</span>
            </div> */}
          </div>

          <div className='COSMIC--shape-container'>
              <svg viewBox='0 0 200 200' className='COSMIC--triangle'>
                <polygon points='0 200, 100 0, 200 200' style={{ fill: '#fff' }} />
              </svg>
              <div className='COSMIC--below-triangle'>
              <div className='COSMIC--below-triangle__space-fix'></div>
              </div>
              <div className='COSMIC--triangle-content'>
                <div className='COSMIC--copy-container'>
                  <div className='COSMIC--copy-left-shape'></div>
                  <div className='COSMIC--copy-right-shape'></div>
                  <div className='COSMIC--copy'>
                    <div>COSMIC</div><div style={{ position: 'relative', top: '-4px', paddingTop: '6px' }}>SYMBOLISM</div>
                    <div className='COSMIC--core-copy' style={{position: 'relative', top: '-2px' }}>is a project of Imaginary Foundation.
                    We collaberated with them to frame their brilliant artwork in
                    an interactive looping Droste like fly-through presentation on the web.
                    The website quickly went viral and received a million visitors within a week of release.
                    </div>

                    {/* <br />
                    Whatever be the actual state of the skill, dexterity,
                    and judgment, with which labour is applied in any nation,
                    the abundance or scantiness of its annual supply must
                    depend, during the continuance of that state, upon the
                    proportion between the number of those who are annually
                    employed in useful labour, and that of those who are not
                    so employed. The number of useful and productive labourers,
                    it will hereafter appear, is everywhere in proportion to the
                    quantity of capital stock which is employed in setting them
                    to work, and to the particular way in which it is so employed.
                    The second book, therefore, treats of the nature of capital
                    stock, of the manner in which it is gradually accumulated, and
                    of the different quantities of labour which it puts into motion,
                    according to the different ways in which it is employed. */}
                  </div>
                </div>

                {/* <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 451.4 451.4'>
                    <g>
                        <rect x='87.51' y='87.51' width='276.38' height='276.38' fill='#000'
                        opacity='0' />
                        <path d='M111.94,388.19H388.32V111.81H111.94Zm-21.41,21.4H409.72V90.41H90.53Z'
                        transform='translate(-24.43 -24.3)' fillRule='evenodd' />
                        <rect x='111.94' y='111.81' width='276.38' height='276.38' transform='rotate(-45 208.583 267.332)'
                        fill='#000' opacity='0' />
                        <path d='M54.7,250,250.13,445.43,445.56,250,250.13,54.57Zm421.13,0L250.13,475.7,24.43,250,250.13,24.3Z'
                        transform='translate(-24.43 -24.3)' fillRule='evenodd' />
                    </g>
                </svg> */}

            </div>
              <TriangleImage
                src={ getImageAssets().cosmicManSquare }
                yOffset={ -17 }
                className='COSMIC--bottom-triangle'
                onClick={ () => window.open( 'https://www.cosmic-symbolism.com/', '_blank' ) }
                imageClassName='COSMIC--bottom-triangle__image' />

          </div>

        </div>
      </div>

    </div >
  )
}
// const fun = (
//   <div onClick={ close } style={{width: '25%'}}>
//     <Eye scale={ 2 } scalePointerOverride={ 2 } />
//   </div>
// )

export default CosmicSymbolism
