import preloadImages from 'modules/preload-images'
import { values } from 'soultrain/lib/object'
import styles from './_variables-and-functions.module.scss'

const getStyle = ( key: keyof typeof styles ) => {
  return styles[ key ]
    .replace( /"+/g, '' )
    .replace( /'+/g, '' )

}
const imagePaths = {
  cosmicMan: getStyle( 'imageCosmicMan' ),
  cosmicManSquare: getStyle( 'imageCosmicManSquare' ),
  epSquare: getStyle( 'imageEpSquare' ),
  epWide: getStyle( 'imageEpWide' ),
  gjelinaWide: getStyle( 'imageGjelinaWide' ),
  gta: getStyle( 'imageGta' ),
  sky: getStyle( 'imageSky' ),
}

type ImagePaths = typeof imagePaths
let preloadState = false

// tslint:disable-next-line:no-shadowed-variable
const doPreload = ( _imagePaths: ImagePaths ): ImagePaths => {
  const preloadedImages = preloadImages( ...values( _imagePaths ) )
  Object.assign( window, { preloadedImages } )
  preloadState = true

  return imagePaths
}

export const getImageAssets = (): ImagePaths => (
  ( preloadState === true )
    ? imagePaths
    : doPreload( imagePaths )
)

export const seaColor = styles.seaColor

export default getImageAssets
