import { DevTo } from 'components/devto/devto'
import ExternalLink from 'components/external-link/external-link'
import Github from 'components/github/github'
// import LinkedIn from 'components/linkedin/linkedin'
import Twitter from 'components/twitter/twitter'
import { classes } from 'modules/helpers'
import * as React from 'react'
import styles from './contact-card.module.scss'
import ContactUs from './contact-us'

const subject = 'INQUERY'
const body = 'Thanks for reaching out!\n\nWe look forward to hearing about your project. Please replace this text with your inquery. Be sure to include your name, phone number, and the best times to reach you.'

const m1 = 'mai'
const m2a = 'lto:'
const m2b = 'inf'
const m3 = 'ormation'
const m4 = [ 'a', 'm', 'e', 'h', '@' ]
  .reverse()
  .join( '' )
const m5 = 'ka.com'
const mto = m1.concat( m2a + m2b + m3 ).concat( m4 + m5 )
  .concat( '?subject=' + encodeURIComponent( subject ) )
  .concat( '&body=' + encodeURIComponent( body ) )
const m = m2b.concat( m3 + m4 ).concat( m5 )

export const ContactCard = () => {

  return (
    <>
      <div className={styles.contactUs}><ContactUs /></div>
      <div className={styles.info}>
        <a href='tel:+13239893989' className={styles.info__p}>323-989-3989</a>
        <a href={mto} className={styles.info__e}>{ m }</a>
      </div >
      <div className={ styles.social }>
        <ExternalLink
          className={ classes( styles.social__item, styles.social__twitter ) }
          href='https://twitter.com/babakness' >
          <Twitter />
        </ExternalLink>
        {/* <ExternalLink
          className={ classes( styles.social__item, styles.social__in )}
          href='https://github.com/babakness' >
          <LinkedIn />
          </ExternalLink> */}
        <ExternalLink
          className={ classes( styles.social__item, styles.social__github ) }
          href='https://github.com/babakness' >
          <Github />
        </ExternalLink>
        <ExternalLink
          className={ classes( styles.social__item, styles.social__devto ) }
          href='https://dev.to/babak' >
          <DevTo />
        </ExternalLink>
      </div>
    </>
  )
}

export default ContactCard
