// import { Linear, TimelineLite} from 'gsap'
import { getStyles } from 'modules/helpers'
import { useEffect, useState } from 'modules/react-16.7-features'
import * as React from 'react'
// import { toArray } from 'soultrain/lib/array/toArray'
// import { log } from 'soultrain/lib/logging'
import { ReactComponent as PyramidSvg } from 'svg/pyramid.svg'
import style from './pyramid.module.scss'

const getPyramidStyles = getStyles( style )

const startAnimation = ( tl: any ) => {

  // ; ( window as any ).tl = tl
  // tslint:disable-next-line:align
  const multi = ( a: number, b: number ): number => a * b
  return ( { x = -800, y = multi( x, .5 ), stagger = .15, transition = .3} ) => {

    return tl
    .staggerFromTo( '.left:nth-child(even)', transition,
      {
        x,
        y,
      },
      {
        x: 0,
        y: 0,
      },
      stagger,
      0 )
    .staggerFromTo( '.left:nth-child(odd)', transition,
      {
        x: x * -1,
        y: y * -1,
      },
      {
        x: 0,
        y: 0,
      },
    stagger,
    0 )
    .staggerFromTo( '.right:nth-child(even)', transition,
      {
        x: x * -1,
        y,
      },
      {
        x: 0,
        y: 0,
      },
    stagger,
    0 )
    .staggerFromTo( '.right:nth-child(odd)', transition,
      {
        x,
        y: y * -1,
      },
      {
        x: 0,
        y: 0,
      },
    stagger,
    0,
    )
  }

}

// const ref = React.createRef<HTMLDivElement>()

interface PyramidProps {
  /**
   * Start animation
   */
  playAnimation?: boolean
  /**
   * x offset
   */
  x?: number
  /**
   * y offset
   */
  y?: number
  /**
   * Time to wait before introducing next pair of crossing bars
   */
  stagger?: number
  /**
   * length of animation duration / time animation transition
   */
  transition?: number
}

/**
 * Hemaka Pyramid logo component.
 * @example
 * // defaults
 * const defaultProps = {
 *  x = -400,
 *  y = x * .5,
 *  stagger = .15,
 *  transition = .2
 * }
 * <Pyramid {...defaultProps } />
 */
function Pyramid( {playAnimation = true, ...props}: PyramidProps ) {
  const [ visibility, setVisibility ] = useState( playAnimation ? 'not-visible' : 'visible' )

  let sideEffect = () => {
    if ( playAnimation ) {
      setVisibility( 'visible' )
      if ( ( window as any ).TimelineLite ) {
        const runAnimation = startAnimation( new ( window as any ).TimelineLite() )
        runAnimation( props )
      }
    }
  }
  useEffect( sideEffect, [] )

  return (
      <PyramidSvg className={ getPyramidStyles( 'pyramid', visibility as 'visible' ) }  / >
  )
}

export default Pyramid
