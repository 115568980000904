import * as React from 'react'

interface TriangleImageProps {
  src: string
  className?: string
  imageClassName?: string
  yOffset?: number
  xOffset?: number
  style?: React.CSSProperties
  onClick?: undefined | ( () => void )
}

// const useDomEventsStyleModifier = ( element: HTMLElement | SVGAElement | SVGElement, events: ( style: CSSStyleDeclaration ) => Partial<GlobalEventHandlers> ) => {
//   React.useEffect( () => {
//     Object.assign( element, events( element.style ) )
//   }, [] )
// }

export const TriangleImage: React.FC<TriangleImageProps> = ( {
    src,
    imageClassName,
    className,
    xOffset = 0,
    yOffset = 0,
    onClick,
    style = ( { width: '100%' } ) },
  ) => {
  // const imageRef = React.useRef( {} as SVGImageElement )
  const id = `triangle-id-${ Math.floor( Math.random() * 100000 ) }`

  // useDomEventsStyleModifier( imageRef.current as SVGElement, ( style ) => ( {
  //   onmousedown: () => Object.assign( style, {
  //     transform: 'scale(1.5)',
  //   } ),
  // } ),
  // )

  // https://speckyboy.com/css-svg-canvas-masks/

  return (
    <svg
      viewBox={ `${ xOffset } ${ yOffset } 100 100` }
      className={ className }>
    <g>
      <clipPath id={ id }>
        <polygon points='0 100, 50 0, 100 100, 0 100' />
      </clipPath>
    </g>
    <g
      clipPath={ `url(#${ id })` }
      style={{ cursor: onClick ? 'pointer' : 'auto' }}
      onClick={ onClick }>
      <image
        width='100'
        height='85'
        xlinkHref={ src }
        style={ { ...style }}
        className={ imageClassName } />
    </g>
  </svg>
  )
}
