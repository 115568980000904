import * as React from 'react'
import Location from 'types/location'

export interface State {
  location: Location
  readonly renders: number
}

// export const Context = React.createContext( {} )

const reducer = ( state: State, stateUpdate: Partial<State> ) => {
  return (
    { ...state, ...stateUpdate, renders: state.renders + 1 }
  )
}

interface ProviderProps {
  children: React.ReactChild
  value: State
}

export const AppContext = React.createContext( {} as [ State, React.Dispatch<Partial<State>> ] )

export const Provider: React.FC<ProviderProps> = ( {value, children } ) => {
  const [ state, setState ] = React.useReducer( reducer, value )
  return (
    <AppContext.Provider value={ [ state, setState ] }>
      { children }
    </AppContext.Provider>
  )
}

export default Provider
